body.cms-no-route {
    .cs-container__inner {
        h1 {
           text-transform: none;
            font-family: 28px;
            line-height: 34px;
            margin-bottom: 25px;
        }

        h2,h3 {
            font-size: 18px;
            text-transform: none;
        }

        ul.buttons-set {
            display: flex;
            flex-direction: row;
            list-style: none;
            padding: 0;
            margin: 20px 0 0;

            button {
                margin-right: 10px;
            }
        }
    }
    .columns {
        .column.main {
            padding: 45px 15px !important;
            max-width: 138rem;
            margin: 0 auto;

            h1 {
                text-transform: none;
                font-family: 28px;
                line-height: 34px;
                margin-bottom: 25px;
            }

            h2,h3 {
                font-size: 18px;
                text-transform: none;
            }

            ul.buttons-set {
                display: flex;
                flex-direction: row;
                list-style: none;
                padding: 0;
                margin: 20px 0 0;

                button {
                    margin-right: 10px;
                }
            }
        }
    }
}
