/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* Radio and checkbox styling */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-newsletter {
  margin: 0;
  width: 100%; }
  .cs-newsletter__form {
    position: relative;
    display: flex;
    overflow: hidden;
    padding-right: 30px !important; }
    @media (min-width: 48.01em) {
      .cs-newsletter__form {
        max-width: 34rem; } }
    @media (max-width: 48em) {
      .cs-newsletter__form {
        max-width: 86%; } }
    @media (min-width: 64em) {
      .cs-newsletter__form {
        max-width: 100%; } }
    @media (max-width: 40em) {
      .cs-newsletter__form {
        padding-right: 0 !important;
        max-width: 100%; } }
  .cs-newsletter__field {
    flex-basis: 100%;
    border-radius: 25px; }
    @media (max-width: 40em) {
      .cs-newsletter__field {
        margin-bottom: 15px; } }
  .cs-newsletter__input {
    margin-top: 0 !important;
    border-radius: 25px; }
    .cs-newsletter__input.input-text {
      margin: 0;
      font-size: 1.2rem;
      line-height: 1.6rem;
      border-radius: 25px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0; }
  .cs-newsletter__actions {
    display: flex;
    align-items: stretch;
    height: 4rem; }
  .cs-newsletter__button {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: uppercase;
    border-radius: 25px;
    font-family: "Red Hat Display";
    padding: 11px 25px;
    font-size: 1.2rem;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    width: 105px;
    padding-right: 25px;
    letter-spacing: 0.6px; }
    .cs-newsletter__button.medium {
      padding: 11px 25px;
      font-size: 1.4rem; }
    .cs-newsletter__button.large {
      padding: 13px 25px;
      font-size: 1.6rem; }
    .cs-newsletter__button::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-newsletter__button, .cs-newsletter__button:visited {
      cursor: pointer;
      border-radius: 25px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid transparent;
      color: #ffffff;
      background-color: #FF4604; }
      .cs-newsletter__button *, .cs-newsletter__button:visited * {
        color: #ffffff;
        fill: #ffffff; }
    .cs-newsletter__button:hover:not([disabled]), .cs-newsletter__button:focus:not([disabled]), .cs-newsletter__button:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid transparent;
      color: #ffffff;
      background-color: #FF7442; }
      .cs-newsletter__button:hover:not([disabled]) *, .cs-newsletter__button:focus:not([disabled]) *, .cs-newsletter__button:active:not([disabled]) * {
        color: #ffffff;
        fill: #ffffff; }
    .cs-newsletter__button[disabled] {
      cursor: auto;
      background-color: #a6e5b5;
      position: relative; }
      .cs-newsletter__button[disabled] span.cs-header-search__button-label {
        z-index: 2;
        position: relative; }
    .cs-newsletter__button-label {
      text-transform: uppercase;
      vertical-align: middle; }
  .cs-newsletter__button-icon {
    max-width: 1em;
    max-height: 1.4rem;
    z-index: 2;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    margin-left: 0;
    left: 1em;
    width: 0.8em;
    height: 0.8em;
    margin-right: 0; }

.newsletter-lock__icon {
  font-size: 12px;
  font-weight: normal;
  background-image: url(../images/icons/lock24@1x.svg);
  background-repeat: no-repeat;
  background-size: 9px;
  background-position: 0px 7px;
  margin-top: 0;
  display: block;
  padding-left: 15px; }
  @media (max-width: 40em) {
    .newsletter-lock__icon {
      margin-top: 0;
      font-weight: normal; } }
