/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-products-list {
  margin: 0;
  padding: 0;
  list-style: none; }
  .cs-products-list__item {
    margin: 0;
    border-top: 1px solid #F0F0F0; }
    @media (max-width: 63.99em) {
      .cs-products-list__item .cs-addtocart__button {
        padding: 7px 5px;
        min-width: 165px; }
        .cs-products-list__item .cs-addtocart__button .cs-addtocart__button-label {
          font-size: 14px;
          padding: 0; }
        .cs-products-list__item .cs-addtocart__button svg.cs-addtocart__button-icon {
          position: relative;
          left: -7px;
          top: 11px;
          width: 18px;
          height: 15px; } }

@media (min-width: 48.01em) and (max-width: 63.99em) {
  .cs-products-list__item .cs-addtocart__button .cs-addtocart__button-label {
    font-size: 12px;
    padding: 0;
    letter-spacing: 1px; } }
    @media (max-width: 39.99em) {
      .cs-products-list__item .cs-addtocart__button .cs-addtocart__button-label {
        font-size: 12px;
        padding: 0;
        letter-spacing: 1px; } }
    .cs-products-list__item .cs-product-tile__stock {
      text-transform: capitalize; }
      @media (max-width: 39.99em) {
        .cs-products-list__item .cs-product-tile__stock {
          padding: 0 9px;
          font-size: 1.4rem;
          line-height: 2.0rem; }
          .cs-products-list__item .cs-product-tile__stock:before {
            width: 10px;
            height: 10px; } }
    .cs-products-list__item .cs-product-tile__badges {
      position: absolute;
      right: 0;
      justify-content: flex-end;
      z-index: 1; }
      .cs-products-list__item .cs-product-tile__badges .cs-product-tile__badge-item {
        padding-right: 0; }
      .cs-products-list__item .cs-product-tile__badges .cs-product-tile__badge {
        font-size: 9px;
        padding: 5px 6px 5px 13px;
        max-width: 80px; }
  .cs-products-list .cs-product-tile__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; }
  .cs-products-list .cs-product-tile__thumbnail {
    width: 135px;
    padding: 10px;
    box-sizing: border-box; }
    @media (max-width: 48em) {
      .cs-products-list .cs-product-tile__thumbnail {
        min-width: 71px;
        padding: 14px 0px;
        width: 71px; } }
    .cs-products-list .cs-product-tile__thumbnail .cs-product-tile__figure {
      padding-bottom: 0; }
  .cs-products-list .cs-product-tile__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 0;
    padding-left: 35px;
    position: relative; }
    @media (max-width: 29.99em) {
      .cs-products-list .cs-product-tile__content {
        flex-direction: column;
        padding-left: 20px;
        position: initial; } }
    @media (max-width: 48em) {
      .cs-products-list .cs-product-tile__content .cs-product-tile__details {
        margin-bottom: 8px;
        flex-shrink: initial; } }
    .cs-products-list .cs-product-tile__content .cs-product-tile__addons {
      display: flex;
      align-items: center;
      flex: 1; }

@media (max-width: 63.99em) and (min-width: 30.01em) {
  .cs-products-list .cs-product-tile__content .cs-product-tile__addons {
    align-items: flex-start; } }
      @media (max-width: 30em) {
        .cs-products-list .cs-product-tile__content .cs-product-tile__addons {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%; } }
    .cs-products-list .cs-product-tile__content .cs-product-tile__addons-sides {
      position: absolute;
      left: 0;
      bottom: 10px;
      text-align: left;
      padding-left: 35px; }
      @media (max-width: 48em) {
        .cs-products-list .cs-product-tile__content .cs-product-tile__addons-sides {
          left: 0;
          bottom: 15px;
          width: auto; } }
      @media (max-width: 30em) {
        .cs-products-list .cs-product-tile__content .cs-product-tile__addons-sides {
          left: 53px;
          bottom: 60px; } }
    .cs-products-list .cs-product-tile__content .cs-product-tile__addons-left {
      display: none; }
    .cs-products-list .cs-product-tile__content .cs-product-tile__addons-top {
      display: none; }
  .cs-products-list .cs-product-tile__main {
    padding-bottom: 40px;
    padding-right: 15px;
    width: 22vw; }
    @media (max-width: 48em) {
      .cs-products-list .cs-product-tile__main {
        padding-bottom: 35px;
        width: 100%;
        padding-right: 0; } }
    @media (max-width: 30em) {
      .cs-products-list .cs-product-tile__main {
        padding-bottom: 85px; } }
  .cs-products-list .cs-buybox__qty-wrapper {
    display: flex; }
    @media (min-width: 48em) {
      .cs-products-list .cs-buybox__qty-wrapper {
        flex-direction: column;
        align-items: flex-start; } }
    .cs-products-list .cs-buybox__qty-wrapper .cs-buybox__qty-label {
      font-size: 1.2rem;
      font-weight: bold;
      margin-right: 7px; }
      @media (min-width: 64em) {
        .cs-products-list .cs-buybox__qty-wrapper .cs-buybox__qty-label {
          margin-top: -13px; } }
    @media (max-width: 48em) {
      .cs-products-list .cs-buybox__qty-wrapper {
        flex-direction: row;
        flex-wrap: wrap; }
        .cs-products-list .cs-buybox__qty-wrapper .cs-buybox__qty-increment {
          flex: 1;
          min-width: 95px; } }
  .cs-products-list .cs-addtocart {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end; }

@media (max-width: 63.99em) and (min-width: 48.01em) {
  .cs-products-list .cs-addtocart {
    flex-direction: column;
    margin: 13px 0 0;
    align-items: flex-end; } }

@media (max-width: 48em) and (min-width: 30.01em) {
  .cs-products-list .cs-addtocart {
    flex-direction: column;
    margin: 18px 0 0; } }
    @media (max-width: 48em) {
      .cs-products-list .cs-addtocart {
        justify-content: space-between; }
        .cs-products-list .cs-addtocart.config-box {
          justify-content: flex-end; } }
    .cs-products-list .cs-addtocart .cs-qty-increment,
    .cs-products-list .cs-addtocart .cs-addtocart__wrapper {
      margin: 0; }
    .cs-products-list .cs-addtocart .cs-qty-increment {
      width: 140px; }
      @media (max-width: 48em) {
        .cs-products-list .cs-addtocart .cs-qty-increment {
          width: auto; } }
      .cs-products-list .cs-addtocart .cs-qty-increment .cs-qty-increment__button {
        min-width: 30px;
        width: 34px;
        max-width: 34px; }
        @media (max-width: 48em) {
          .cs-products-list .cs-addtocart .cs-qty-increment .cs-qty-increment__button {
            width: 28px;
            height: 28px;
            max-width: 28px; }
            .cs-products-list .cs-addtocart .cs-qty-increment .cs-qty-increment__button svg {
              width: 10px; } }
      @media (max-width: 48em) {
        .cs-products-list .cs-addtocart .cs-qty-increment .cs-qty-increment__input {
          width: 30px;
          max-width: 30px;
          min-width: 30px;
          height: 28px;
          font-size: 1.4rem; } }
    .cs-products-list .cs-addtocart .cs-addtocart__wrapper {
      flex: 1;
      max-width: 220px;
      margin-left: 50px; }

@media (max-width: 63.99em) and (min-width: 48.01em) {
  .cs-products-list .cs-addtocart .cs-addtocart__wrapper {
    margin-top: 15px;
    margin-bottom: 10px; } }

@media (max-width: 48em) and (min-width: 30.01em) {
  .cs-products-list .cs-addtocart .cs-addtocart__wrapper {
    margin-top: 35px; } }
      @media (max-width: 48em) {
        .cs-products-list .cs-addtocart .cs-addtocart__wrapper {
          max-width: 165px;
          margin-left: 10px; } }
  .cs-products-list .cs-product-tile__name {
    margin-top: 15px; }
    .cs-products-list .cs-product-tile__name a {
      width: 22vw; }
      @media (max-width: 48em) {
        .cs-products-list .cs-product-tile__name a {
          display: block;
          width: 100%; } }
  .cs-products-list:not(.cs-products-list--carousel) .cs-products-list__item {
    padding: 0; }
    .cs-products-list:not(.cs-products-list--carousel) .cs-products-list__item:last-child {
      border-bottom: 0; }
  @media (min-width: 48em) {
    .cs-products-list--carousel .cs-products-list__item {
      border-bottom: 1px solid #f0f0f0;
      padding: 0; }
      .cs-products-list--carousel .cs-products-list__item:last-child {
        border-bottom: 0; } }

.products-crosssell {
  padding-top: 60px; }
  @media (max-width: 64em) {
    .products-crosssell .cs-headline {
      margin-bottom: 0; } }
  .products-crosssell .cs-headline__title {
    text-align: center;
    font-size: 28px;
    letter-spacing: 1px;
    justify-content: center; }
    @media (max-width: 64em) {
      .products-crosssell .cs-headline__title {
        font-size: 18px; } }
