/**
 * Footer component customization.
 * @see /Magento_Theme/templates/footer/columns.phtml for templates.
 */
@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/visually-hidden';
@import 'components/pseudoicon/mixin';
@import 'components/typography/mixin';

$footer_text-color: $color_text-200 !default;
$footer_background: #000 url('../images/footer-banner.jpg') no-repeat !default;
$footer_edge-gutter: $page_edge-gutter !default;

$footer_section-edge-gutter: $page_edge-gutter !default;
$footer_section-vertical-offset: 2em !default;

$footer_section-separator-border: 1px solid $color_gray !default;

$footer_section-title-headline-level: $font-size_headline-4 !default;
$footer_section-title-padding: $footer_section-vertical-offset
    $footer_section-edge-gutter 0.7em !default;
$footer_section-title-color: $color_white !default;
$footer_section-title-font-size: 1.4rem !default;
$footer_section-title-transform: uppercase !default;

$footer_section-pseudoicon-include: true !default;

$footer_promo-display-on-mobile: false !default;
$footer_logo-display: block !default;
$footer_logo-width: 13.5em !default;
$footer_logo-height: 2.3em !default;

.#{$ns}footer {
    $root: &;
    font-size: 1rem;
    color: $footer_text-color;
    overflow: hidden;
    padding: 0 0 2rem;
    position: relative;

    @include media('<=tablet') {
        padding: 0;
    }

    &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 43%;
        height: 100%;
        content: '';
        background: #000;
    }

    &:after {
        background: $footer_background;
        background-size: cover;
        position: absolute;
        right: 0;
        top: 0;
        width: 57%;
        height: 100%;
        content: '';
    }

    @include media('<=tablet') {
        &:before,
        &:after {
            display: none;
        }
    }

    @include media('>tablet') {
        background-position-x: 43vw;
        padding: 0 $footer_edge-gutter;
    }

    &__content {
        padding: 0;
        position: relative;
        z-index: 2;

        @include media('>tablet') {
            display: flex;
            flex-wrap: wrap;
            padding-top: 3.8rem;
            padding-bottom: 2.5rem;
        }

        @include media('>=laptop') {
            flex-wrap: nowrap;
        }

        @include media('<=tablet') {
            flex-direction: column;
        }
    }

    &__item {
        @include media('>=laptop') {
            flex: 1;
        }

        &--promo {
            @if $footer_promo-display-on-mobile == false {
                @include media('<=tablet') {
                    display: none;
                }
            }

            flex-basis: 100%;

            @include media('<laptop') {
                margin-bottom: 1.5rem;
            }

            @include media('>=laptop') {
                flex-basis: auto;
                flex-grow: 1;
            }
        }

        &--links {
            @include media('>tablet') {
                display: flex;
                flex-basis: auto;
                flex-grow: 1;
            }

            @include media('<=tablet') {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                background: $color_black;
                padding: 25px 10px 30px;

                .cs-footer__section {
                    flex: 1 1 33%;
                    max-width: 260px;
                    padding-left: 10px;
                }
            }
        }

        &--extras {
            @include media('>tablet') {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                flex-direction: column;
                flex-basis: 34%;
                flex-grow: 1;
            }

            @include media('<=tablet') {
                padding: 25px 20px 25px;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                background: $footer_background;
                background-size: cover;
            }
            @include media('<=phoneLg') {
                flex-direction: column;
            }
        }
    }

    &__section {
        @include media('>tablet') {
            flex: 1;
        }

        &--newsletter {
            display: flex;
            flex-direction: column;

            .top-links__phone-icon {
                order: 1;
                letter-spacing: 1px;
                background: url(../images/icons/telephone66.svg) no-repeat;
                background-size: 19px;
                background-position: left 6px;
                font-size: 24px;
                font-weight: bold;
                text-transform: uppercase;
                color: #ffffff;
                line-height: 28px;
                padding: 1px 0 0 28px;

                @include media('<=tablet') {
                    font-size: 18px;
                    background-size: 16px;
                    background-position: left 7px;
                }
            }

            h3 {
                @include media('>tablet') {
                    margin-top: 18px;
                }
            }

            .cs-footer__section-title {
                order: 2;
            }
            .cs-newsletter {
                order: 3;

                p {
                    font-size: $font-size_paragraph;

                    @include media('<=tablet') {
                        font-size: 1.2rem;
                    }
                }
            }

            @include media('>tablet') {
                flex-grow: 2;
                max-width: 615px;
            }
        }

        &--socials {
            @include media('>=laptop') {
                margin-top: 4rem;
            }
        }

        &--stores-switcher {
            @include media('>=laptop') {
                margin-top: 4rem;
                max-width: 20rem;
            }
        }
    }

    &__section-title,
    [data-role='title'] {
        @if $footer_section-title-headline-level != 'none' {
            @include headline($level: $footer_section-title-headline-level);
        }
        position: relative;
        margin: 25px 0 0;
        padding: $footer_section-title-padding;
        color: $footer_section-title-color;
        font-size: $footer_section-title-font-size;
        font-weight: 600;
        text-transform: $footer_section-title-transform;
        letter-spacing: 0.3px;
        padding-left: 0;
        padding-top: 0;
        padding-right: 0;

        @include media('<=tablet') {
            border-top: none;

            #{$root}__section[data-mage-init] &,
            #{$root}__section[data-collapsible] & {
                cursor: pointer;

                @if $footer_section-pseudoicon-include {
                    @include pseudoicon();
                }
            }

            #{$root}__section--socials & {
                @include visually-hidden();
            }
        }

        @include media('<=tablet') {
            font-size: 1.2rem;
            margin: 20px 0 0;
        }
    }

    &__section-content,
    [data-role='content'] {
        padding: 0 $footer_section-edge-gutter $footer_section-edge-gutter;
        font-size: 1.2em;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        @include media('<=tablet') {
            #{$root}__section[data-mage-init] &,
            #{$root}__section[data-collapsible] & {
                display: none;
            }
        }
    }

    &__shop-logo {
        display: $footer_logo-display;
        width: $footer_logo-width;
        height: $footer_logo-height;
        min-width: $footer_logo-width;
        max-width: 100%;
        max-height: 100%;
    }

    .social-links {
        list-style: none;
        padding: 0;
        margin: 15px 0 0;

        @include media('>tablet') {
            align-self: flex-end;
            max-width: 615px;
            width: 100%;
        }

        @include media('<=tablet') {
            width: 36%;
            text-align: right;
            padding-bottom: 10px;
        }

        @include media('<=phoneLg') {
            width: 100%;
            text-align: left;
        }

        li {
            display: inline-block;
        }

        &__link {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            &:after {
                content: '';
                display: block;
                background-size: contain;
                background-position: center;
                width: 44px;
                height: 44px;
            }
        }

        .social-facebook {
            &:after {
                background: url('../images/icons/facebook@1x.svg') no-repeat;
            }
        }

        .social-youtube {
            &:after {
                background: url('../images/icons/youtube@1x.svg') no-repeat;
                margin-left: 13px;
            }
        }

        .social-instagram {
            &:after {
                background: url('../images/icons/instagram@1x.svg') no-repeat;
                margin-left: 13px;
            }
        }
    }
}

.cs-container {
    &--footer {
        max-width: none;
    }
}

.u-float-right {
    float: right;
}
.u-float-left {
    float: left;
}
.clearfix {
    display: flow-root;
}

.Landing-page-view__product {
    width: calc(100% - 45.5%);
}

.Landing-page-view__product-image {
    max-width: 500px;
}

.Landing-page-view__sales-copy {
    width: 45.5%;
    .cms-floating-image {
        float: right;
        margin: 0 0 50px 50px;
    }
}

.Landing-page-view__locations {
    clear: both;
    padding-top: 30px;
    h3 {
        text-transform: uppercase;
    }
    .row {
        @extend .clearfix;
        clear: both;
        margin-bottom: 15px;
    }
}
.Landing-page-view__location {
    border: 1px solid #eee;
    float: left;
    margin-right: 1.69492%;
    padding: 15px;
    width: 32.20339%;
    &:last-child {
        margin-right: 0;
    }
    a {
        color: $color_primary-orange;
        text-decoration: underline;
    }
}

.Landing-page-view__cta-call.h2 {
    font-size: 1.6em;
    text-align: center;
    a {
        color: $color_primary-orange;
        text-decoration: underline;
    }
}
