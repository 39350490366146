@import 'config/variables';
@import 'vendors/include-media';
@import 'components/container/mixin';
@import 'components/container/no-root';

$container_background-top-bar: $color_background-500 !default;
.#{$ns}container {
    $root: &;

    @include container();
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;

    &__inner {
        @include container-wrapper();
        position: relative;

        &:before,
        &:after {
            content: '';
            display: table;
            clear: both;
        }

        &--flex {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @include media('>=tablet') {
                flex-wrap: nowrap;
            }

            &:after {
                display: none;
            }
        }
    }

    &__aside {
        width: 100%;

        @include media('>=tablet') {
            width: 33%;
        }

        @include media('>=laptop') {
            width: 24.8%;
        }

        @include media('>=desktop') {
            width: 24.8%;
        }
    }

    &__main-column {
        width: 56%;
        position: relative;

        @include media('>=tablet') {
            width: 65%;
        }

        @include media('>=laptop') {
            width: 71.4%;
        }

        @include media('>=desktop') {
            width: 71.4%;
        }

        @include media('<tablet') {
            max-width: 100% !important;
            width: 100% !important;
            //min-height: 30rem;
        }

        // Bundle products do not have sticky buybox and should have another layout.
        // On tablet gallery covered buybox and as a result it was impossible to click at anything there.
        // Code below should be reviewed during refactoring containers and pdp (PDP 2.0)
        .page-product-bundle & {
            @include media('>=tablet', '<laptop') {
                width: 100%;
                margin-right: 0;
            }
        }

        &--space-top {
            @include media('>=laptop') {
                padding-top: 3rem;
            }
        }
    }

    &__reviews {
        @extend .#{$ns}container__inner;

        @include media('>=tablet') {
            background: $color_white;
            margin-top: 3rem;
            padding: 0 2rem;
        }

        @include media('>=desktop') {
            padding: 2rem 0 0;
        }

        &:after {
            content: '';
            display: table;
            clear: both;
        }
        padding-bottom: 2rem;
    }

    &__cart-coupons {
        display: flex;
        flex-wrap: wrap;

        @include media('>=laptop') {
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }

    &--contrast {
        background-color: $color_gallery;

        @include media('>=laptop') {
            background-image: url('../images/background.png');
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    &--top-bar {
        background-color: transparent;
        max-width: none;
    }

    &--spacious {
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include media('>=laptop') {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }

        .catalog-category-view &,
        .catalogsearch-result-index & {
            @include media('>=tablet') {
                padding-top: 0;
            }
        }
    }

    &--image-teaser-window-width,
    &--store-locator {
        padding-left: 0;
        padding-right: 0;
    }

    &--grid-teaser {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }

    &--hero-teaser {
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;
    }

    &--noscroll {
        overflow: hidden;
    }

    &--pdp {
        @include media('>=laptop') {
            padding-top: 0;
        }
    }

    &--usps {
        #{$root}--headline {
            padding: 0;
        }
    }

    &--create-account-page,
    &--login-page,
    &--success-page,
    &--logout-page {
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include media('>=laptop') {
            padding-top: 4rem;
            padding-bottom: 8rem;
        }
    }

    &--my-account-page {
        @include media('>=tablet', '<laptop') {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }

    &--ontop {
        z-index: 301;
    }

    &--viewport-width {
        max-width: none;
    }

    &--page-pdp-details-aw_pq_ajax_container {
        @include media('<tablet') {
            padding: 0;
        }
    }

    &--cart-page {
        #{$root}--products-carousel {
            padding-left: 0;
            padding-right: 0;
        }

        #{$root}--headline {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &--cms-teaser {
        margin-top: 2rem;
    }

    @supports (display: grid) and (grid-auto-flow: row) {
        &--grid-teaser {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            overflow: visible;
        }
    }
}

.contact-index-index {
    .cs-breadcrumbs {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .cs-cms-contact-form {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        margin: 20px 0 80px;

        @include media('<tablet') {
            flex-direction: column;
            flex-wrap: wrap;
            margin: 20px 0 45px;
        }
    }
    .main {
        .contact-form {
            @include media('>=tablet') {
                flex: 1 1 68%;
            }

            @include media('<tablet') {
                order: 2;
            }

            fieldset.fieldset {
                padding: 0;
                border: 0;

                h3 {
                    text-transform: none;

                    @include media('<=phoneLg') {
                      margin-bottom: 5px;
                    }
                }

                > span {
                    display: inline-block;
                }
            }

            .flex-row {
                @include media('>phoneLg') {
                    display: flex;
                    flex-direction: row;
                }

                .field {
                    margin-top: 0;
                    @include media('>phoneLg') {
                        flex: 1 1 48%;
                    }

                    &:last-child {
                        @include media('>phoneLg') {
                            margin-left: 20px;
                        }
                    }


                }
            }

            .primary {
                text-align: right;
            }
        }

        .contact-address {
            background: $color_light-gray;
            padding: 20px 25px;


            @include media('<=phoneLg') {
                padding: 10px 15px;
            }

            @include media('<tablet') {
                order: 1;
                margin-bottom: 35px;
            }

            &__item{
                margin-bottom: 20px;
                cursor: pointer;

                @include media('<=phoneLg') {
                    margin-bottom: 15px;
                }
            }

            p {
                margin: 0;
            }

            @include media('<=phoneLg') {
               p,
                span {
                   font-size: 1.4rem;
               }
            }

            .icon-text{
                display: block;
                padding-left: 25px;
                padding-right: 5px;
                position: relative;

                &:before {
                    background-size: 10px 15px;
                    background-repeat: no-repeat;
                    background-position: center;
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 5px;
                    width: 15px;
                    height: 15px;
                }
            }

            .icon-location {
                &:before {
                    background-image: url("../images/icons/map-pin.svg");
                }
            }

            .icon-phone{
                 &:before {
                     background-image: url("../images/icons/phone-dark.svg");
                     background-size: contain;
                 }
             }

            .icon-email {
                &:before {
                    background-image: url("../images/icons/email@1x.svg");
                    background-size: contain;
                    width: 17px;
                    top: 6px;
                }
            }

            @include media('>=tablet') {
                flex: 1 1 33%;
                margin-left: 20px;
            }

        }
    }
}

.cms-page-view.page-layout-cms-full-width-narrow,
.cms-page-view.page-layout-cms-full-width,
.cms-page-view.page-layout-1colum{
    .columns .cs-container--brand-carousel{
        @include media('>tablet') {
            margin-top: 45px;
        }

        @include media('<=tablet') {
            margin-top: 35px;
        }
    }

    .cs-container--breadcrumbs {
        .cs-breadcrumbs {
            padding-top: 1.5rem;

            @include media('<tablet') {
                margin-bottom: 10px;
            }
        }

    }

    .columns .column.main {
        padding-top: 0;

        @include media('>tablet') {
            padding-bottom: 30px;
        }

        @include media('<=tablet') {
            padding-bottom: 15px;
        }
    }
}

.cms-page-view.page-layout-cms-full-width-narrow{
    .cs-container--breadcrumbs,
    .columns .cs-container:not(.cs-container--brand-carousel){
        .cs-container__inner {
            max-width: 103rem;
        }
    }
}
