@import 'config/variables';
@import 'components/field/mixin';

/* stylelint-disable no-descending-specificity */

// Our BEM component.
.cs-field,
// Left for now for compatibility sake.
.cs-input {
    @include field();

    &__control {
        @include field-control();
    }

    &__label {
        @include field-label();

        .required & {
            @include field-label-required();
        }
    }

    &__input,
    &__textarea {
        @include field-input();

        &.mage-error {
            @include field-input-error();
        }
    }

    &__select {
        @include field-select();
    }

    &__radio {
        @include field-radio();

        // To rewrite to proper scss syntax after getting rid of .cs-input class
        & + .cs-input__label,
        & + .cs-field__label {
            @include field-radio-label();
        }
    }

    &__checkbox {
        @include field-checkbox();

        &.mage-error {
            @include field-input-error();
        }

        // To rewrite to proper scss syntax after getting rid of .cs-input class
        & + .cs-input__label,
        & + .cs-field__label {
            @include field-checkbox-label();
        }
    }

    &__icon {
        @include field-icon();
    }

    &__note {
        @include field-note();
    }

    &__error {
        @include field-error();
    }
}
// Magento classes used in checkout, login/register forms etc.
.field {
    @include field();

    &.note {
        font-size: 1.6rem;
        letter-spacing: 0.5px;
    }

    &._success:not(._warn) {
        .control {
            position: relative;

            &:after {
                @include field-icon('success');
                content: '';
            }
        }
    }

    .control {
        @include field-control();

        > textarea {
            @include field-input();
        }
    }

    .label {
        @include field-label();
    }

    &.required,
    &._required {
        > .label {
            @include field-label-required();
        }
    }

    &.required {
        ._required {
            > .label {
                &:after {
                    // Hide asterisks for labels inside fieldsets as they are usually rendered empty.
                    display: none;
                }
            }
        }
    }

    .input-text {
        @include field-input();

        &.mage-error {
            @include field-input-error();
        }

        &.required-entry {
            margin-bottom: 1rem;
        }
    }

    .select {
        @include field-select();
    }

    .checkbox {
        @include field-checkbox();

        &.mage-error {
            @include field-input-error();
        }

        & + .label {
            @include field-checkbox-label();
        }
    }

    .radio {
        @include field-radio();

        & + .label {
            @include field-radio-label();
        }
    }

    &.choice {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        label {
            margin: 0;
            flex: 1;
            font-weight: normal;
            font-size: 1.6rem;
        }

        div.mage-error {
            width: 100%;
        }

        input[type="radio"] + label {
            margin-left: 10px;
        }
    }

    &.customer-name-prefix {
        select {
            @include field-select();
        }
    }

    &._error {
        .input-text,
        .checkbox {
            @include field-input-error();
        }
    }

    // Reverting styles for input with tooltip.
    .control._with-tooltip input {
        width: 100%;
        margin-right: 0;
    }
    // Reverting info tooltip styles to make it appear as note below the input.
    .field-tooltip {
        position: static;

        .label,
        .field-tooltip-action {
            display: none;
        }

        .field-tooltip-content {
            position: static;
            background: none;
            border: none;

            @include field-note();

            &:before,
            &:after {
                display: none;
            }
        }
    }

    .note {
        @include field-note();
    }
}
// Has to be scoped to div, otherwise it collides with input class.
div.mage-error,
div.field-error {
    @include field-error();
}
.opc-wrapper,
.estimate {
    .select-wrap {
        position: relative;

        select {
            padding-right: 50px;
        }

        &:after {
            content: '';
            background-color: $color_black;
            display: inline-block;
            position: absolute;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            background-image: url("../images/icons/white-arrow.svg");
            width: 44px;
            height: 40px;
            background-repeat: no-repeat;
            background-size: 15px;
            background-position: center;
            top: 0;
            right: 0;
            cursor: pointer;
            pointer-events: none;
        }
    }
}

small.required-fields {
    display: block;
    text-align: right;
    font-size: 1.2rem;
    color: $color_gray;
    span {
        color: $color_primary-red;
    }
}

/* stylelint-enable */
