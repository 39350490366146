@import 'config/variables';
@import 'vendors/include-media';

$messages_success-background-color: transparent !default;
$messages_info-background-color: transparent !default;
$messages_notice-background-color: transparent !default;
$messages_error-background-color: transparent !default;

$messages_success-border: 1.5px solid $color_success !default;
$messages_info-border: 1.5px solid $color_info !default;
$messages_notice-border: 1.5px solid $color_warn !default;
$messages_error-border: 1.5px solid $color_error !default;

$messages_success-color: $color_success !default;
$messages_info-color: $color_info !default;
$messages_notice-color: $color_warn !default;
$messages_error-color: $color_error !default;


$messages_border-radius: 0 !default;
$messages_padding: 1rem 1rem 1.2rem !default;
$messages_text-color: $color_text-500 !default;
$message_icon-width: 1.5rem !default;
$message_icon-height: $message_icon-width !default;

.messages {
    max-width: $page_max-width;
    width: 100%;
    margin: 0 auto;
}

.#{$ns}messages__message,
.message {
    margin: 1rem 0;
    padding: $messages_padding;
    border-radius: $messages_border-radius;
    background-repeat: no-repeat;
    background-position: 1.5rem center;
    background-size: $message_icon-width $message_icon-height;
    padding-left: 4.3rem;
}

.#{$ns}messages__message--success,
.message.success {
    background-color: $messages_success-background-color;
    background-image: url('../images/messages/success.svg');
    border: $messages_success-border;
    background-size: 1.9rem 1.9rem;
    .#{$ns}messages__text,
    span  {
        color: $messages_success-color;
    }
}

.#{$ns}messages__message--notice,
.message.warning {
    background-color: $messages_notice-background-color;
    background-image: url('../images/messages/warning.svg');
    border: $messages_notice-border;
    background-position: 1.6rem center;
    background-size: 1.6rem 1.6rem;
    .#{$ns}messages__text,
    span  {
        color: $messages_notice-color;
    }
}

.#{$ns}messages__message--info,
.message.info,
.message.notice {
    background-color: $messages_info-background-color;
    background-image: url('../images/messages/info.svg');
    border: $messages_info-border;
    background-position: 1.6rem center;
    background-size: 1.5rem 1.5rem;
    .#{$ns}messages__text,
    span  {
        color: $messages_info-color;
    }
}

.#{$ns}messages__message--error,
.message.error {
    background-color: $messages_error-background-color;
    background-image: url('../images/messages/remove.svg');
    border: $messages_error-border;
    .#{$ns}messages__text,
    span  {
        color: $messages_error-color;
    }
}

.#{$ns}messages__text,
.message span {
    font-size: 1.6rem;

    @include media('<=tablet') {
        line-height: 20px;
    }
}

.#{$ns}messages {
    $root: &;

    &--space-top {
        margin-top: 1.5rem;
    }
}

.increment-notification {
    margin-top: 15px;
    display: inline-block;
    line-height: 20px;
    max-width: 100%;
    width: 100%;
    background-position: 1.6rem 1.2rem !important;
}
