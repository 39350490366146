@import './mixin';
$default-grid-columns: 10;
$default-grid-gutter: 20px;

.grid-container {
    @include grid($gutter: $default-grid-gutter);

    &.layout-column {
        flex-direction: column;
    }

    @for $i from 1 through $default-grid-columns {
        .col-#{$i} {
            @include grid-col($col: $i, $grid-columns: $default-grid-columns, $gutter: $default-grid-gutter, $width: true);
        }
    }

    @include media('>tablet') {
        @for $i from 1 through $default-grid-columns {
            > .lg__col-#{$i} {
                @include grid-col($col: $i, $grid-columns: $default-grid-columns, $gutter: $default-grid-gutter, $width: true);
            }
        }
    }

    @include media('<=tablet', '>phoneLg') {
        @for $i from 1 through $default-grid-columns {
            > .md__col-#{$i} {
                @include grid-col($col: $i, $grid-columns: $default-grid-columns, $gutter: $default-grid-gutter);
            }
        }
    }

    @include media('<=phoneLg') {
        flex-direction: column;
        flex-wrap: wrap;

        @for $i from 1 through $default-grid-columns {
            > .sm__col-#{$i} {
                @include grid-col($col: $i, $grid-columns: $default-grid-columns, $gutter: $default-grid-gutter);
            }
        }
    }
}

.content-short {
    max-width: 860px;
    margin: 0 auto;
    padding: 0 10px;
}

.v-align {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.narrow-content {
    max-width: 860px !important;
    margin: 20px auto;
}

