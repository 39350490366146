/*
 * Because price-box is used in multiple components like tile, buybox etc,
 * we need to move variables to separate file to have possibility to include them
 * in each component without generate price-box component styles multiple times
*/

$price-box_normal-price-color: $color_black !default;
$price-box_normal-price-font-size: 1.4rem !default;
$price-box_normal-price-font-weight: 400 !default;

$price-box_special-price-color: $color_primary-red !default;
$price-box_special-price-font-size: $price-box_normal-price-font-size !default;
$price-box_special-price-font-weight: $price-box_normal-price-font-weight !default;

$price-box_old-price-color: $color_black !default;
$price-box_old-price-font-size: $price-box_normal-price-font-size !default;
$price-box_old-price-font-weight: $price-box_normal-price-font-weight !default;
$price-box_old-price-text-decoration: line-through !default;

$price-box_label-color: $color_black !default;
$price-box_label-font-size: $price-box_normal-price-font-size !default;
$price-box_label-font-weight: $price-box_normal-price-font-weight !default;
$price-box_label-display-colon: true !default;
$price-box_label-margin: 0.3rem !default;

$price-box_space-between-prices: 1rem !default;
