@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/link';

$topbar_background: transparent !default;
$topbar_height: 2.7rem !default;

$topbar_item-font-size: $font-size_caption !default;
$topbar_item-text-color: $color_white !default;
$topbar_item-hours-color: $color_black !default;
$topbar_item-border: 0.2rem solid $color_white !default;
$topbar_item-border-transform: $transform_skew-400 !default;
$topbar_item-phone_number-color: $color_black !default;

$topbar_link-color-action: $color_white !default;
$topbar_link-color: $color_white !default;

.#{$ns}topbar {
    $root: &;
    background: transparent;
    display: flex;
    width: 100%;
    font-size: $topbar_item-font-size;
    transform: translateY(0);
    transition: transform 0.3s;
    z-index: 302;

    &--hidden {
        transform: translateY(-105%);
    }

    .page-print & {
        display: none;
    }

    &__wrapper {
        display: flex;
        flex-wrap: nowrap;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;


        @include media('>=laptop') {
            justify-content: flex-end;
        }

        @include media('>=laptop') {
            padding-right: 18px;
            padding-top: 5px;
        }

        @include media('<laptop') {
            padding: 0 25px;
        }

        @include media('<tablet') {
            padding: 0 15px;
        }
    }

    &__item {
        color: $topbar_item-text-color;
        display: flex;
        flex: 0 0 auto;
        white-space: nowrap;
        height: 100%;

        &--hotline {
            align-items: center;

            ul {
                list-style: none;
                display: flex;
                flex-direction: row;
                padding: 0;
                margin: 0;

                li {
                    display: inline-block;
                    margin: 0;

                    @include media('<=tablet') {
                        &:not(:last-child){
                            display: none;
                        }
                    }
                }
            }

            a {
                @include link($topbar_link-color, $topbar_link-color-action);
                padding: 8px 0 0;
                display: block;
                letter-spacing: 0.5px;

                &:hover {
                    color: $color_primary-500;
                }

                @include media('>=tablet') {
                    padding: 8px 15px 0 0;
                }

                @include media('>=tablet') {
                    padding: 0 15px 0 0;
                }

                @include media('>=laptop') {
                    padding: 10px 28px 0;
                }
            }

            @include media('<=laptop') {
               width: initial;
            }

            @include media('>=laptop') {
                flex: 1;
                justify-content: flex-end;
            }
        }

        &--authorization {
            margin-left: 30px;
        }
    }

    &__phone-number {
        color: $topbar_item-hours-color;
        font-weight: 700;
        margin-right: 0.9rem;

        &:hover {
            color: $color_primary-500;
        }
    }
}
