@import 'config/variables';
@import 'vendors/include-media';
@import 'components/container/mixin';

$header_font-size: 1rem !default;
$header_border-bottom: 1px solid $color_border-200 !default;

$header_is-sticky-on-mobile: true !default;
$header_background: $color_background-200 !default;

// Horizontal align of the searchbox if it's displayed as a layer - `$global_header-searchbox-as-layer-breakpoint`
$header_searchbox-justify-content: flex-end !default;

// If you want to cover 'search' item in header by searchbox, provide proper negative margin based on your design
// It works only if '$global_header-searchbox-as-layer-breakpoint' has valid breakpoint and `$header_searchbox-justify-content` = flex-end
$header_searchbox-margin-to-cover-search-item: -6.8rem !default;

.#{$ns}container {
    &--header-container {
        background: url("../images/header-banner.jpg") no-repeat;
        background-position: center;
        background-size: cover;
    }

    &--top-bar,
    &--header {
        max-width: 1420px;
        margin: 0 auto;
        padding: 0 15px;

        @include media('<laptop') {
            padding: 0;
        }
    }

    &__navigation-inner
    {
        max-width: 1440px;
        margin: 0 auto;
    }
}
.#{$ns}header {
    $root: &;
    font-size: $header_font-size;

    @include media('<laptop') {
        padding-bottom: 6px;
        margin-bottom: 71px;
    }

    @include media('<=tablet') {
        padding-bottom: 9px;
        margin-bottom: 61px;
    }

    &__content {
        z-index: 300;
        display: flex;
        align-items: center;
        justify-content: space-between;
        will-change: z-index, height, position;
        background-color: transparent;
        position: relative;
        max-width: 100vw;
        flex-wrap: nowrap;

        @include media('>=tablet', '<laptop') {
            padding: 0 8px;
        }

        @include media('<=tablet') {
            .cs-header__offcanvas-toggle {
                order:1;
            }
            .cs-header__logo {
                order:2;
            }
            .cs-header-user-nav {
                order:3;
            }
        }
    }

    &__offcanvas-toggle {
        height: 100%;
        flex: 0 1 55px;

        @include media('>=tablet') {
            flex: 0 1 75px;
            .cs-offcanvas-toggle {
                width: 55px;
            }
        }

        @include media('>=laptop') {
            display: none;
        }
    }

    &__logo {
        display: flex;
        margin-bottom: 0;
        padding: 0;
        margin-top: 0;

        @include media('<laptop') {
            align-items: center;
            justify-content: center;
        }

        @include media('>=laptop') {
            padding: 0;
            margin-top: -20px;
            max-width: 310px;
            width: 25vw;
            margin-left: 19px;
        }

        .cs-logo {
            @include media('<laptop') {
                max-width: 195px;
                margin-top: 0;
            }

            @include media('<=tablet') {
                max-width: 225px;
                margin-top: -20px;
            }
            @include media('<=phoneLg') {
                max-width: 185px;
                margin-top: -2px;
            }
        }

        .page-print & {
            @include container-wrapper();
            position: relative;
            height: auto;
            padding: 2rem 0;

            &:after {
                content: '';
                display: table;
                clear: both;
            }
        }

        .cs-logo__image {
            height: auto;
            width: 100%;
        }
    }

    &__search {
        display: block;
        width: 100%;
        background-color: $color_gray;
        padding: 1.2rem 2.4rem;
        position: absolute;
        top: 58px;
        left: 0;
        right: 0;
        z-index: 300;

        @include media('<=phoneLg') {
            padding: 1.2rem 1.5rem;
        }

        @include media('>tablet') {
            top: 64px;
        }

        @include media('>=laptop') {
            flex-grow: 1;
            width: auto;
            padding: 0 25px 0 45px;
            position: initial;
            margin-left: 0 !important;
            justify-content: $header_searchbox-justify-content;
            background: transparent;

            .ie11 & {
                position: inherit;
                top: inherit;
            }
        }

        &--active {
            display: flex;
        }

        @if ($global_header-hide-search-item-trigger-breakpoint) {
            @if ($global_header-hide-search-item-trigger-breakpoint == true) {
                display: flex;
            } @else {
                @include media(
                    $global_header-hide-search-item-trigger-breakpoint
                ) {
                    display: flex;
                }

                @if ($global_header-searchbox-as-layer-breakpoint) {
                    @include media(
                        $global_header-searchbox-as-layer-breakpoint
                    ) {
                        position: relative;
                        top: initial;
                    }
                }
            }
        }

        @if (
            $global_header-hide-search-item-trigger-breakpoint and
                $header_searchbox-justify-content ==
                'flex-end'
        ) {
            @if ($global_header-hide-search-item-trigger-breakpoint == true) {
                margin: 0;
            } @else {
                margin-right: $header_searchbox-margin-to-cover-search-item;

                @include media(
                    $global_header-hide-search-item-trigger-breakpoint
                ) {
                    margin: 0;
                }
            }
        } @else {
            @if ($header_searchbox-justify-content == 'flex-end') {
                margin-right: $header_searchbox-margin-to-cover-search-item;
            }
        }
    }

    &__user-nav {
        display: flex;
        justify-content: flex-end;
        flex: 0 1 75px;

        @include media('>=laptop') {
            flex: 0 1 27%;
        }
    }

    .top-links__phone-icon {
        letter-spacing: 0.4px;
        background: url("../images/icons/telephone66.svg") no-repeat;
        background-size: 18px;
        background-position: left 46%;
        font-size: 23px;
        font-weight: bold;
        text-transform: uppercase;
        color: $color_white;
        line-height: 28px;
        padding: 18px 15px 0 25px;

        @include media('<laptop') {
            display: none;
        }
    }
}

@if not($header_is-sticky-on-mobile) {
    @include media('<laptop') {
        .cs-container--header {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
