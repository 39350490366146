@import 'config/variables';
@import 'components/container/mixin';
@import 'components/button/mixin';
@import 'vendors/include-media';

$addtocart_button-type: 'addto' !default;
$addtocart_button-icon-position: left !default;
$addtocart_button-color: $color_white !default;
$addtocart_button-icon-width: 2.2rem !default;
$addtocart_button-icon-height: $addtocart_button-icon-width !default;
$addtocart_button-icon-edge-gutter: 33px !default;

$addtocart_button-skewed-background-width: 5.1rem !default;
$addtocart_button-padding-left: 1rem !default;
$addtocart_button-padding-right: 4.5rem !default;

$addtocart_loading-indicator-ball-color: $color_text-200 !default;
$addtocart_loading-indicator-ball-size: 10px !default;

$addtocart_feedback-bg-skew: $transform_skew-400 !default;

$addtocart_ajax-success-bg-primary: $color_success-500 !default;
$addtocart_ajax-success-bg-secondary: $color_success-600 !default;
$addtocart_ajax-success-color: $color_white !default;
$addtocart_ajax-success-fill: $addtocart_ajax-success-color !default;

$addtocart_ajax-fail-bg-primary: $color_error-500 !default;
$addtocart_ajax-fail-bg-secondary: $color_error-600 !default;
$addtocart_ajax-fail-color: $color_white !default;
$addtocart_ajax-fail-fill: $addtocart_ajax-fail-color !default;

.cs-buybox__update {
    padding: 14px;
    max-width: 219px;
}

.#{$ns}addtocart {
    $root: &;
    text-align: right;
    padding-right: 0;

    &__wrapper {
        // because button can't have overflow, we need to add it on button wrapper
        margin-top: 15px;

        @include media('<=tablet') {
            text-align: center;
            padding: 10px 0;
        }
    }

    &__button {
        @include button(
            $type: $addtocart_button-type,
            $icon_pos: $addtocart_button-icon-position
        );

        svg.cs-addtocart__configure-icon {
            display: none;
        }

        &.configure {
            .cs-addtocart__button-label {
                padding: 0;
            }
        }

        padding: 13px 14px;
        max-width: 219px;

        &:after {
            top: 3px;
        }

        &[disabled] {
            opacity: 1;
        }

        &,
        &:hover,
        &:focus,
        &:active {
            #{$root}__success-label {
                color: $addtocart_ajax-success-color;
            }


            #{$root}__success-icon {
                * {
                    fill: $addtocart_ajax-success-fill;
                }
            }

            #{$root}__fail-label {
               display: none;
            }

            #{$root}__fail-icon {
                * {
                    fill: $addtocart_ajax-fail-fill;
                }
            }
        }
    }

    &__button-label,
    &__success-label,
    &__fail-label {
        @include button_span(
            $type: $addtocart_button-type,
            $icon_pos: $addtocart_button-icon-position
        );
        padding-left: 30px;
        white-space: nowrap;
    }

    &__configure-icon,
    &__button-icon,
    &__success-icon,
    &__fail-icon {
        @include button_icon(
            $type: $addtocart_button-type,
            $icon_pos: $addtocart_button-icon-position
        );
        padding: 0;
        max-width: none;
        max-height: none;
        width: $addtocart_button-icon-width;
        height: $addtocart_button-icon-height;
        /* stylelint-disable */
        @if ($addtocart_button-icon-position == 'right') or ($addtocart_button-icon-position == 'left') {
            #{$addtocart_button-icon-position}: $addtocart_button-icon-edge-gutter;
        }
        /* stylelint-enable */
    }

    &__fail-icon {
        transition: none;
    }

    &__button-label,
    &__success-label {
        transition: opacity 0.15s linear,
            transform 1s cubic-bezier(0, 0.77, 0.18, 1);
    }

    &__button-label {
        display: inline-block;
        z-index: 4;
        font-size: 1.6rem;
        position: relative;
        text-transform: uppercase;
    }

    &__loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: scale(0) translate3d(-50%, -50%, 0);
        width: $addtocart_loading-indicator-ball-size * 4;
        height: $addtocart_loading-indicator-ball-size;
        opacity: 0;
        transition: opacity 0.15s linear,
            transform 1s cubic-bezier(0, 0.77, 0.18, 1);
        transition-delay: 0.2s;

        &-ball {
            position: absolute;
            display: block;
            width: $addtocart_loading-indicator-ball-size;
            height: $addtocart_loading-indicator-ball-size;
            bottom: 0;
            background: $addtocart_loading-indicator-ball-color;
            border-radius: 50%;

            &--1 {
                left: 0;
            }

            &--2 {
                left: 15px;
                animation-delay: 0.15s !important;
            }

            &--3 {
                left: 30px;
                animation-delay: 0.3s !important;
            }
        }
    }

    &__success-label,
    &__success-icon,
    &__fail-label,
    &__fail-icon {
        opacity: 0;
    }

    &__success-icon,
    &__fail-icon {
        height: 1.725em;
        transition-delay: 0.2s;
    }

    &__success-label,
    &__fail-label {
       display: none;
    }

    &__minicart {
        width: 100%;
        text-align: center;

        &--sticky,
        &--unstick {
            @include container-wrapper();
            width: 100%;
            position: fixed;
            top: 0;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            z-index: 400;
            padding: 0;
            text-align: right;
            display: flex;
            justify-content: flex-end;

            .ui-dialog {
                right: 0 !important;
            }

            .block-minicart {
                &:after {
                    right: 3.2rem !important;
                }
            }
        }
    }

    &__minicart-link {
        @include media('>=laptop') {
            min-width: 125px;
        }
        #{$root}__minicart--sticky &,
        #{$root}__minicart--unstick & {
            text-align: center;
            padding: 2.5rem 1rem;
            background: $color_background-200;
            box-shadow: 1px 3px 4.5rem 0 rgba(30, 48, 67, 0.34);
            animation: minicart-in 1s cubic-bezier(0, 0.77, 0.18, 1);
            position: relative;
        }

        #{$root}__minicart--unstick & {
            animation: minicart-out 0.5s cubic-bezier(0.55, 0.2, 0.89, 0.45);
        }

        &:before {
            content: none !important;
        }
    }

    &__minicart-qty-badge-wrapper {
        $badge-wrapper: &;

        position: absolute;
        opacity: 0;
        z-index: 600;
        transition: top 0.9s cubic-bezier(0.42, 0.31, 0.34, 0.93),
            left 0.9s cubic-bezier(0.42, 0.31, 0.34, 0.93);
        pointer-events: none;

        &--animating {
            animation: move-qty-badge 1.2s cubic-bezier(0.42, 0.31, 0.34, 0.93);
            position: fixed;
            top: 0.5rem;

            @include media('>tablet') {
                top: 1.3rem;
            }

            @include media('>=laptop') {
                position: absolute;
                top: 5.3rem;
            }

            &#{$badge-wrapper}--minicart-sticky {
                position: fixed;
                top: 1.8rem;
            }
        }

        #{$root}__minicart-qty-badge {
            position: static;
            visibility: visible;
        }
    }

    &--loading,
    &--done {
        #{$root}__button-label {
            opacity: 0;
            transform: translate3d(2em, 0, 0);
        }
    }

    &--loading {
        #{$root}__loading {
            opacity: 1;
            transform: scale(1) translate3d(-50%, -50%, 0) translateX(-1.25em); // calc() doesn't work in IE so i had to duplicate translateX() transform to adjust X position

            &-ball {
                animation: ball-in 1s infinite;
            }
        }
    }

    &--done {
        #{$root}__button-icon {
            opacity: 0;
        }

        #{$root}__feedback-bg {
            transform: $addtocart_feedback-bg-skew translate3d(0, 0, 0);

            &:after {
                transform: translate3d(
                    -#{$addtocart_button-skewed-background-width + 0.2},
                    0,
                    0
                );
            }
        }
    }

    &--success {
        #{$root}__success-icon {
            opacity: 1;
        }

        #{$root}__success-label {
            opacity: 1;
            transform: translate3d(-50%, -50%, 0);
        }
    }

    &--fail {
        #{$root}__fail-icon {
            opacity: 1;
        }
    }

    &--animation-done {
        #{$root}__button-label,
        #{$root}__button-icon {
            transition-delay: 0.5s;
        }

        #{$root}__feedback-bg {
            transition-delay: 0.2s;

            &,
            &:after {
                transition: transform 0.4s cubic-bezier(0.86, 0.01, 0.83, 0.51);
            }

            &:after {
                transition-delay: 0;
            }
        }

        #{$root}__success-label,
        #{$root}__fail-icon {
            transition: opacity 0.6s linear,
                transform 0.6s cubic-bezier(0.86, 0.01, 0.83, 0.51);
            transition-delay: 0;
        }

        #{$root}__success-icon,
        #{$root}__fail-icon {
            transition-delay: 0;
        }
    }

    /* stylelint-disable */
    @keyframes ball-in {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes minicart-in {
        0% {
            transform: translate3d(0, -100%, 0);
        }
        100% {
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes minicart-out {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(0, -100%, 0);
        }
    }

    @keyframes move-qty-badge {
        0% {
            opacity: 1;
        }
        15% {
            transform: scale(1);
        }
        80% {
            opacity: 1;
            transform: scale(1.5);
        }
        100% {
            opacity: 0;
            transform: scale(1);
        }
    }
    /* stylelint-enable */

    .block-minicart {
        margin-bottom: 0;
    }
}
