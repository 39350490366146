/**
 * USPs component customization.
 * @see /MageSuite_ContentConstructorFrontend for layout, templates and images.
 * USPs component is based on Image Teaser.
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-image-teaser--usps .cs-image-teaser__slides {
  align-items: flex-start; }

.cs-image-teaser--usps .cs-image-teaser__slide {
  margin: 0; }

.cs-image-teaser--usps:not(.cs-image-teaser--slider) .cs-image-teaser__slides {
  flex-wrap: nowrap; }

.cs-image-teaser--usps .cs-image-teaser__image {
  max-width: 80%;
  max-height: 6.5rem;
  margin: 0 auto 1rem; }
  .ie11 .cs-image-teaser--usps .cs-image-teaser__image {
    height: 100%; }

.cs-image-teaser--usps .cs-image-teaser__text {
  display: block; }

.cs-image-teaser--usps .cs-image-teaser__nav {
  display: none !important; }
