@import 'config/variables';
@import 'config/breakpoints';
@import 'vendors/include-media';
@import 'components/teaser/mixins';

$brand-carousel_carousel-padding: 0 !default;
$brand-carousel_carousel-padding--slider: 0 3.8rem !default;

$brand-carousel_min-slide-width: 3rem !default;
$brand-carousel_min-slide-margin: 0 2rem 0 0 !default;

$brand-carousel_max-image-height: 8.5rem !default;

$brand-carousel_nav-button-width: 3.5rem !default;
$brand-carousel_nav-button-height: 5rem !default;
$brand-carousel_nav-button-box-shadow: none !default;
$brand-carousel_nav-button--hover-box-shadow: $brand-carousel_nav-button-box-shadow !default;

$brand-carousel_hide-nav-buttons-on-mobile: true !default;
$teaser_hide-nav-buttons-on-mobile: $brand-carousel_hide-nav-buttons-on-mobile !default;

$teaser_nav-button-width: $brand-carousel_nav-button-width !default;
$teaser_nav-button-height: $brand-carousel_nav-button-height !default;
$teaser_nav-button-box-shadow: $brand-carousel_nav-button-box-shadow !default;
$teaser_nav-button--hover-box-shadow: $brand-carousel_nav-button--hover-box-shadow !default;
$component-spacing-configuration: 1.5rem 0 !default;
$teaser_hide-nav-buttons-on-mobile: false !default;

.cs-container--brand-carousel{
    margin: 0 auto;
}

.#{$ns}brand-carousel {
    $root: &;

    @include teaser();
    margin: 15px 0;
    position: relative;

    @include media('<tablet') {
       padding: 0 25px;
    }

    @include media('<phoneLg') {
        padding: 0 20px;
    }

    &__carousel {
        padding: $brand-carousel_carousel-padding;

        @include media('>=tablet') {
            #{$root}--slider & {
                padding: $brand-carousel_carousel-padding--slider;
            }
        }
    }

    &__slides {
        align-items: center;
        justify-content: center;

        #{$root}--slider & {
            justify-content: space-between;
        }
    }

    &__slide {
        min-width: $brand-carousel_min-slide-width;
        max-width: none;
        width: auto;
        margin: $brand-carousel_min-slide-margin;

        &:last-child,
        #{$root}--slider & {
            margin-right: 0;
        }
    }

    &__image {
        width: auto;
        max-width: 100%;
        max-height: $brand-carousel_max-image-height;
    }

    &__nav {
        z-index: 3;
        box-shadow: none !important;
        width: $brand-carousel_nav-button-width;
        // Do not display navigation, when teaser destroyed on resize.
        #{$root}:not(#{$root}--slider) & {
            display: none;
        }

        &:hover,
        &:active,
        &:focus {
            background: transparent;
            border: none;
            box-shadow: $brand-carousel_nav-button-box-shadow;
        }

        &-icon {
            width: 1.4rem;
            height: 2.7rem;

            @include media('<phoneLg') {
                width: 9px !important;
            }
        }


        &--prev {
            left: 0 !important;

            @include media('<tablet') {
                display: block !important;
                opacity: 1 !important;
                width: 2.5rem;
            }

            @include media('<phoneLg') {
                left: -10px !important;
            }
        }

        &--next {
            right: 0 !important;

            @include media('<tablet') {
                display: block !important;
                opacity: 1 !important;
                width: 2.5rem;
            }

            @include media('<phoneLg') {
                right: -10px !important;
            }
        }

    }

    &__pagination {
        @include media('>=tablet') {
            display: none;
        }

        &:not(:empty) {
            margin-top: 1.5rem;
        }
    }

    &--static {
        width: 100%;
        padding: $brand-carousel_carousel-padding;
    }
}

.slick-slider {
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-touch-action: pan-y;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    box-sizing: border-box;
    display: block;
    position: relative;
    touch-action: pan-y;
    user-select: none;
}
.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    [dir='rtl'] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        border: 1px solid transparent;
        display: block;
        height: auto;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-prev {
    left: -1.2rem;
    background-image: url('../images/icons/arrow-left-2@1x.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
}
.slick-next {
    right: -1.2rem;
    background-image: url('../images/icons/arrow-right@1x.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
}
.slick-prev,
.slick-next {
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    background-size: 11px;
    background-position: center;
    border: none;
    border-radius: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 44px;
    line-height: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 44px;
    z-index: 101;

    &.slick-disabled {
        opacity: .2;
    }


    &:hover {
        border: none;
        &.slick-disabled {
            opacity: .2;
        }
    }

    &:before,
    &:after {
        opacity: 1;
    }

    &:active,
    &:focus,
    &:not(.primary) {
        border: none;
        box-shadow: none;

        &:active {
            box-shadow: none;
        }
    }
}
