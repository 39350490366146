/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-header-user-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%; }
  @media (min-width: 64em) {
    .cs-header-user-nav {
      padding: 0 18px 0 0;
      min-width: 365px; } }
  .cs-header-user-nav__item {
    margin-bottom: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; }
    .cs-header-user-nav__item--search {
      display: none; }
  .cs-header-user-nav__link {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0; }
    @media (min-width: 64em) {
      .cs-header-user-nav__link {
        padding: 17px 0 20px 20px; } }
    .cs-header-user-nav__link:hover {
      text-decoration: none; }
      .cs-header-user-nav__link:hover .cs-header-user-nav__icon path {
        fill: #FF4604; }
      .cs-header-user-nav__link:hover .cs-header-user-nav__label {
        color: #FF4604; }
    .cs-header-user-nav__link--search:hover {
      cursor: pointer; }
    @media (max-width: 63.99em) {
      .cs-header-user-nav__link.showcart {
        padding-right: 18px; }
        .cs-header-user-nav__link.showcart .cs-header-user-nav__label {
          font-size: 0; }
          .cs-header-user-nav__link.showcart .cs-header-user-nav__label > span {
            font-size: 1.2rem; } }
    @media (max-width: 47.99em) {
      .cs-header-user-nav__link.showcart {
        padding-right: 15px; } }
  .cs-header-user-nav__icon-wrapper {
    position: relative; }
  .cs-header-user-nav__icon {
    width: 2.6rem;
    height: 2.6rem;
    display: block;
    margin: 0 auto; }
    .cs-header-user-nav__icon path {
      fill: #FF4604; }
  .cs-header-user-nav__label {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    margin: 4px 0 0 12px;
    color: #ffffff;
    text-decoration: none;
    display: block;
    vertical-align: middle; }
    @media (max-width: 63.99em) {
      .cs-header-user-nav__label {
        margin: 4px 0 0 6px; } }
  .cs-header-user-nav__qty-counter--wishlist {
    color: #ffffff; }
  .cs-header-user-nav__qty-counter--minicart {
    color: #ffffff; }
  .cs-header-user-nav__qty-counter--have-items {
    opacity: 1; }
  .cs-header-user-nav__qty-counter-span {
    min-width: 1ch; }

.cs-topbar__wrapper .cs-header-user-nav__link {
  padding: 0;
  margin: 0; }

.cs-topbar__wrapper .cs-header-user-nav__icon-wrapper {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  margin-top: 10px;
  padding: 0 15px; }
  @media (min-width: 64em) {
    .cs-topbar__wrapper .cs-header-user-nav__icon-wrapper {
      margin-top: 10px; } }

.cs-topbar__wrapper .cs-header-user-nav__label {
  color: #000;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 24px;
  font-weight: bold;
  margin: 1px 0 1px 7px; }
  @media (min-width: 64em) {
    .cs-topbar__wrapper .cs-header-user-nav__label {
      margin: 3px 0 2px 10px; } }

.cs-topbar__wrapper .cs-header-user-nav__icon {
  width: 15px;
  height: 17px;
  display: block;
  margin: 0 auto; }
  .cs-topbar__wrapper .cs-header-user-nav__icon path {
    fill: #000; }
