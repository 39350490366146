/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Mixins for flexible containers declaration.
 */
/**
 * Defines page container. This mixin makes sure that the content has always equal padding
 * for mobile screens on both size of the device.
 */
/**
 * Negates parent container's padding and sets it own.
 * Somethimes this has to be done to make background even to other containers' content.
 */
/**
 * Defines container wrapper that makes sure contnet doesn't extend above certain width and is always centered.
 */
.cs-container--header-container {
  background: url("../images/header-banner.jpg") no-repeat;
  background-position: center;
  background-size: cover; }

.cs-container--top-bar, .cs-container--header {
  max-width: 1420px;
  margin: 0 auto;
  padding: 0 15px; }
  @media (max-width: 63.99em) {
    .cs-container--top-bar, .cs-container--header {
      padding: 0; } }

.cs-container__navigation-inner {
  max-width: 1440px;
  margin: 0 auto; }

.cs-header {
  font-size: 1rem; }
  @media (max-width: 63.99em) {
    .cs-header {
      padding-bottom: 6px;
      margin-bottom: 71px; } }
  @media (max-width: 48em) {
    .cs-header {
      padding-bottom: 9px;
      margin-bottom: 61px; } }
  .cs-header__content {
    z-index: 300;
    display: flex;
    align-items: center;
    justify-content: space-between;
    will-change: z-index, height, position;
    background-color: transparent;
    position: relative;
    max-width: 100vw;
    flex-wrap: nowrap; }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-header__content {
    padding: 0 8px; } }
    @media (max-width: 48em) {
      .cs-header__content .cs-header__offcanvas-toggle {
        order: 1; }
      .cs-header__content .cs-header__logo {
        order: 2; }
      .cs-header__content .cs-header-user-nav {
        order: 3; } }
  .cs-header__offcanvas-toggle {
    height: 100%;
    flex: 0 1 55px; }
    @media (min-width: 48em) {
      .cs-header__offcanvas-toggle {
        flex: 0 1 75px; }
        .cs-header__offcanvas-toggle .cs-offcanvas-toggle {
          width: 55px; } }
    @media (min-width: 64em) {
      .cs-header__offcanvas-toggle {
        display: none; } }
  .cs-header__logo {
    display: flex;
    margin-bottom: 0;
    padding: 0;
    margin-top: 0; }
    @media (max-width: 63.99em) {
      .cs-header__logo {
        align-items: center;
        justify-content: center; } }
    @media (min-width: 64em) {
      .cs-header__logo {
        padding: 0;
        margin-top: -20px;
        max-width: 310px;
        width: 25vw;
        margin-left: 19px; } }
    @media (max-width: 63.99em) {
      .cs-header__logo .cs-logo {
        max-width: 195px;
        margin-top: 0; } }
    @media (max-width: 48em) {
      .cs-header__logo .cs-logo {
        max-width: 225px;
        margin-top: -20px; } }
    @media (max-width: 40em) {
      .cs-header__logo .cs-logo {
        max-width: 185px;
        margin-top: -2px; } }
    .page-print .cs-header__logo {
      max-width: 124rem;
      width: 100%;
      margin: 0 auto;
      position: relative;
      height: auto;
      padding: 2rem 0; }
      .page-print .cs-header__logo:after {
        content: '';
        display: table;
        clear: both; }
    .cs-header__logo .cs-logo__image {
      height: auto;
      width: 100%; }
  .cs-header__search {
    display: block;
    width: 100%;
    background-color: #6E6E6E;
    padding: 1.2rem 2.4rem;
    position: absolute;
    top: 58px;
    left: 0;
    right: 0;
    z-index: 300;
    margin-right: -6.8rem; }
    @media (max-width: 40em) {
      .cs-header__search {
        padding: 1.2rem 1.5rem; } }
    @media (min-width: 48.01em) {
      .cs-header__search {
        top: 64px; } }
    @media (min-width: 64em) {
      .cs-header__search {
        flex-grow: 1;
        width: auto;
        padding: 0 25px 0 45px;
        position: initial;
        margin-left: 0 !important;
        justify-content: flex-end;
        background: transparent; }
        .ie11 .cs-header__search {
          position: inherit;
          top: inherit; } }
    .cs-header__search--active {
      display: flex; }
    @media (min-width: 64em) {
      .cs-header__search {
        display: flex; } }
    @media (min-width: 64em) {
      .cs-header__search {
        position: relative;
        top: initial; } }
    @media (min-width: 64em) {
      .cs-header__search {
        margin: 0; } }
  .cs-header__user-nav {
    display: flex;
    justify-content: flex-end;
    flex: 0 1 75px; }
    @media (min-width: 64em) {
      .cs-header__user-nav {
        flex: 0 1 27%; } }
  .cs-header .top-links__phone-icon {
    letter-spacing: 0.4px;
    background: url("../images/icons/telephone66.svg") no-repeat;
    background-size: 18px;
    background-position: left 46%;
    font-size: 23px;
    font-weight: bold;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 28px;
    padding: 18px 15px 0 25px; }
    @media (max-width: 63.99em) {
      .cs-header .top-links__phone-icon {
        display: none; } }
