/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-cookie-message {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 1.2rem;
  background-color: #ffffff;
  color: #000;
  box-shadow: 0 -0.3rem 0.5rem 0 rgba(0, 0, 0, 0.15);
  font-size: 85%;
  z-index: 98;
  transform: translate3d(0, 110%, 0);
  opacity: 0;
  transition: transform 0.25s ease-out, opacity 0.18s linear;
  display: flex;
  justify-content: center;
  align-items: center; }
  .cs-cookie-message--shown {
    z-index: 99;
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .cs-cookie-message__inner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-left: 8rem; }
    @media (min-width: 48em) {
      .cs-cookie-message__inner {
        flex-direction: row; } }
  .cs-cookie-message__button-wrapper {
    align-self: flex-start; }
    @media (min-width: 48em) {
      .cs-cookie-message__button-wrapper {
        align-self: unset; } }
  .cs-cookie-message__text {
    position: relative;
    margin: 0; }
    .cs-cookie-message__text .block-cms-link.widget,
    .cs-cookie-message__text p {
      margin: 0;
      display: inline; }
    .cs-cookie-message__text a {
      color: #FF4604; }
      .cs-cookie-message__text a:visited {
        color: #FF7442; }
      .cs-cookie-message__text a:hover {
        color: #FF7442; }
      .cs-cookie-message__text a:active, .cs-cookie-message__text a:focus {
        color: #FF7442; }
  .cs-cookie-message__icon {
    position: absolute;
    left: 0.5rem;
    width: 5.5rem;
    height: 5.5rem;
    top: 50%;
    transform: translateY(-50%); }
    @media (min-width: 48em) {
      .cs-cookie-message__icon {
        top: -3.5rem;
        transform: translateY(0); } }
  .cs-cookie-message__button {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: uppercase;
    border-radius: 25px;
    font-family: "Red Hat Display";
    padding: 11px 25px;
    font-size: 1.2rem;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color;
    white-space: nowrap;
    margin-top: 1.2rem; }
    .cs-cookie-message__button.medium {
      padding: 11px 25px;
      font-size: 1.4rem; }
    .cs-cookie-message__button.large {
      padding: 13px 25px;
      font-size: 1.6rem; }
    .cs-cookie-message__button::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-cookie-message__button, .cs-cookie-message__button:visited {
      cursor: pointer;
      border-radius: 25px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid transparent;
      color: #ffffff;
      background-color: #FF4604; }
      .cs-cookie-message__button *, .cs-cookie-message__button:visited * {
        color: #ffffff;
        fill: #ffffff; }
    .cs-cookie-message__button:hover:not([disabled]), .cs-cookie-message__button:focus:not([disabled]), .cs-cookie-message__button:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid transparent;
      color: #ffffff;
      background-color: #FF7442; }
      .cs-cookie-message__button:hover:not([disabled]) *, .cs-cookie-message__button:focus:not([disabled]) *, .cs-cookie-message__button:active:not([disabled]) * {
        color: #ffffff;
        fill: #ffffff; }
    .cs-cookie-message__button[disabled] {
      cursor: auto;
      background-color: #a6e5b5;
      position: relative; }
      .cs-cookie-message__button[disabled] span.cs-header-search__button-label {
        z-index: 2;
        position: relative; }
    @media (min-width: 48em) {
      .cs-cookie-message__button {
        margin-top: 0;
        margin-left: 1.2rem; } }
  .cs-cookie-message__button-span {
    margin-top: 0;
    z-index: 1;
    font-weight: 700;
    vertical-align: baseline;
    display: inline-block;
    line-height: 1.275em;
    letter-spacing: normal;
    padding-right: 4em; }
  .cs-cookie-message__button-icon {
    max-width: 1em;
    max-height: 1.4rem;
    z-index: 2;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    margin-right: 0;
    right: 1em; }
