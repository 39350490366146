@import 'config/variables';
@import 'config/breakpoints';
@import 'vendors/include-media';
@import 'utils/aspect-ratio';
@import 'utils/component-spacing';
@import 'components/teaser/mixins';

$products-carousel_tile-ratio-width: $tile_aspect-ratio-width !default;
$products-carousel_tile-ratio-height: $tile_aspect-ratio-height !default;

$products-carousel_hide-nav-buttons-on-mobile: false !default;
$products-carousel_nav-button-position-top: calc(
    50% - 1.5rem
) !default; // 1.5rem recompenses half of height of the bullet-pagination

$teaser_hide-nav-buttons-on-mobile: $products-carousel_hide-nav-buttons-on-mobile;
$teaser_nav-button-position-top: $products-carousel_nav-button-position-top;
$teaser_nav-button-box-shadow: none !default;
$teaser_nav-button-background: transparent !default;

.#{$ns}products-carousel {
    $root: &;

    @include teaser();
    @include component-spacing();

    &__nav {
        @include media('>=phoneLg') {
            display: none !important;
        }
    }

    &--list {
        #{$root}__nav,
        #{$root}__pagination,
        #{$root}__scrollbar {
            display: none !important;
        }
    }

    &__pagination {
        display: none !important;
    }

    &__wrapper {
        overflow: visible;

        @include media('<=laptop') {
            overflow: hidden;
        }

        @include media('>=laptop') {
            &:hover {
                z-index: 5;
            }
        }
    }

    &__slides {
        #{$root}--list & {
            flex-wrap: wrap;
        }
    }

    &__slide {
        #{$root}:not(.cs-products-carousel--list) & {
            opacity: 0;
            backface-visibility: hidden;
            overflow: hidden;
            transition: transform 0.15s linear, opacity 0.25s linear 0.1s;

            &:not(#{$root}__slide--in-viewport) {
                transform: scale(0);
            }

            &--in-viewport {
                opacity: 1;
                transform: scale(1);
            }

            &:hover {
                @include media('>=laptop') {
                    z-index: 5;
                    overflow: visible;
                }
            }
        }
    }

    &__product-tile {
        width: 100%;

        #{$root}:not(.cs-products-carousel--list) & {
            @include aspect-ratio(
                $width: $products-carousel_tile-ratio-width,
                $height: $products-carousel_tile-ratio-height,
                $childClass: #{$ns}product-tile,
                $autoposition: true
            );
        }

        .cs-product-tile__main {
            @include media('<phoneLg') {
                padding: 10px 12px;

                .cs-product-tile__name {
                    font-size: 1.5rem;
                }
            }
        }
    }

    &__scrollbar {
        height: 0.5rem;
    }

    @supports (display: grid) and (grid-auto-flow: row) {
        &__product-tile {
            #{$root}:not(.cs-products-carousel--list) & {
                @include aspect-ratio-reset(
                    $childClass: #{$ns}product-tile,
                    $autoposition: true
                );
            }
        }
    }

    @include media('<phoneLg') {
        .cs-product-tile__container {
            padding: 1.5rem 4rem;
        }

        .cs-products-carousel__nav--prev {
            left: 0;
        }

        .cs-products-carousel__nav--next {
            right: 0;
        }
    }
}

.products-related,
.products-upsell,
.products-crosssell {
    margin-top: 65px;

    @include media('<tablet') {
        margin-top: 35px;
    }


    .cs-headline__title {
        font-size: 3rem;

        @include media('<tablet') {
            font-size: 1.8rem;
        }
    }
}
