@import 'config/variables';
@import 'vendors/include-media';

$offcanvas_top-offset-mobile: 0 !default; // Move below the navigation on mobile.
$offcanvas_top-offset-tablet: 0 !default; // Move below the navigation on tablet.
$offcanvas_transition-time: $transition-default-time !default;

$offcanvas_overlay-background: rgba($color_background-800, 0.7) !default;

$offcanvas_drawer-background: $color_light-gray !default;

$offcanvas_spinner-color: $color_primary-500 !default;

.#{$ns}offcanvas {
    $root: &;

    position: relative;
    z-index: 300;

    &__drawer,
    &__overlay {
        position: fixed;
        left: 0;
        top: $offcanvas_top-offset-mobile;

        @include media('>=tablet') {
            top: $offcanvas_top-offset-tablet;
        }

        @include media('>=laptop') {
            display: none;
        }

        #{$root}--mini-cart & {
            display: block;
            top: 0;
        }
    }

    &__overlay {
        width: 100%;
        height: 100%;
        background-color: transparent;
        // Setting 1ms because 0 doesn't seem to work and we need seamless transition for scale here.
        transition: background-color $offcanvas_transition-time,
            transform 1ms $offcanvas_transition-time;
        transform: scale(0);
        cursor: pointer;
    }

    .overlay {
        &--visible {
            transform: scale(1);
            background: $offcanvas_overlay-background;
            transition: background-color $offcanvas_transition-time;
        }
    }

    &__drawer {
        position: fixed;
        bottom: 0;
        background: $offcanvas_drawer-background;
        transition: transform $offcanvas_transition-time;
        width: 72%;
        max-width: 280px;

        #{$root}--side_left & {
            left: 0;
            right: auto;
            transform: translate3d(-105%, 0, 0);
        }

        #{$root}--side_right & {
            left: auto;
            right: 0;
            transform: translate3d(105%, 0, 0);
        }
    }

    .drawer {
        &--visible {
            transform: translate3d(0, 0, 0) !important;
        }
    }

    &__spinner {
        position: absolute;
        width: 6rem;
        height: 6rem;
        left: 50%;
        top: 20%;
        margin-left: -3rem;
    }
}
