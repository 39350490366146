@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/component-spacing';

/**
 * Gap between columns (all CSS units allowed) [Example: 1.5rem]
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/column-gap
 */
$paragraph_column-gap: 6rem !default;
/**
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/column-rule
 */
$paragraph_column-rule: 1px solid $color_border-500 !default;

.#{$ns}paragraph {
    column-gap: $paragraph_column-gap;
    column-rule: $paragraph_column-rule;
    @include component-spacing();

    img {
        width: 100%;
        height: auto;
    }
    a.button {
        margin-top: 15px;
    }

    h6 {
        font-size: 2.1rem;
        text-transform: none;
        line-height: 38px;
        font-weight: 400;
        color: $color_black;

        strong {
            font-weight: 400;
        }
    }

    h3,
    h4 {
        text-transform: none;
    }


    @include media('>=tablet') {
        &--cols-2,
        &--cols-3,
        &--cols-4 {
            column-count: 2;

            li {
                width: 100%;
            }

            img {
                display: block;
                max-width: 100%;
            }
        }
    }

    @include media('>=laptop') {
        &--cols-3,
        &--cols-4 {
            column-count: 3;
        }
    }

    @include media('>=laptopLg') {
        &--cols-4 {
            column-count: 4;
        }
    }

    &--mode-optimal {
        max-width: 40em;
        margin-left: auto;
        margin-right: auto;
        column-count: initial;
    }
}

.cms-page-view {
    .main .cs-paragraph {
        p,
        h6 {
            margin: 0 0 25px;
        }
    }
}


.ips-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    @include media('>=tablet') {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .content {
        h2 {
            font-family: $font_family-primary;
            font-size: 1.8rem;
            text-transform: capitalize;
            margin-bottom: 20px;
            font-weight: bold;
            line-height: 26px;
        }
        p{
            font-size: 1.6rem;
            color: $color_body-text;
            font-weight: lighter;
            line-height: 24px;
        }
    }
}


.ips-section-wrapper {
    margin-left: -30px;
    margin-right: -30px;
}

.js-ips-section {
    padding: 0 6px;

    @include media('>=phoneLg') {
        padding: 0 10px;
    }

    @include media('>=tablet') {
        padding: 0 15px;
    }

    .ips-section-inner {
        background-color: $color_light-gray;
        text-align: center;
        padding: 15px 10px;
        height: 100%;

        @include media('>=tablet') {
            padding: 15px 20px;
        }
    }

    .title {
        padding: 0 5px;
        margin-bottom: 17px;

        h4 {
            font-family: $font_family-primary;
            font-size: 1.8rem;
            text-transform: capitalize;
            margin-bottom: 20px;
            font-weight: bold;
        }
        span {
            font-size: 1.6rem;
            color: $color_body-text;
            font-weight: lighter;
            line-height: 24px;
        }
    }
    .options {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

.ips-item {
    text-align: center;
    min-height: 40px;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-family: $font_family-primary;
    font-weight: bold;
    padding: 9px;
    max-width: 225px;
    width: 100%;
    display: block;
    border: 2px solid $color_black;
    border-radius: 50px;
    margin: 0 auto 1rem;
    cursor: pointer;

    &.selected,
    &:hover{
        background-color: $outdoor_primary_dark;
        border-color: $outdoor_primary_dark;
        color: $color_white;
    }
}

.hide-recommendations {
    display: none;
}

.ips-wrapper .content {
    padding-right: 15px;
}

.ips-section-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include media('>=tablet') {
        flex-direction: row;
        flex-wrap: nowrap;
    }
}

.ips-section-wrapper > div {
    width: 100%;
    width: calc(100%/2);
    margin-bottom: 15px;

    @include media('>=tablet') {
        width: calc(100%/4);
        margin-bottom: 0;
    }
}

.js-ips-products-list {
    display: flex;
    flex-direction: column;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 30px;
    margin-bottom: 15px;

    @include media('>=phoneLg') {
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 45px;
        margin-bottom: 55px;
    }

    @include media('>=tablet') {
        flex-direction: row;
        flex-wrap: nowrap;
    }
}

.js-ips-products-list ul {
    list-style: none;
    padding: 0;
}

.js-ips-products {
    margin-top: 55px;
    margin-left: -15px;
    margin-right: -15px;
    h2{
        font-size: 2.8rem;
        font-family: $font_family-primary;
        text-transform: none;
        margin-bottom: 20px;
        font-weight: bold;
        line-height: 28px;
    }
    > p {
        font-size: 1.6rem;
        color: $color_body-text;
        font-weight: lighter;
        line-height: 24px;
    }

    .ips-product {
        &.show {
            border: 2px solid $color_main__brand;
            a {
                font-weight: bold;
                color: $color_black;
            }
        }

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            color:$color_dark-gray;
            letter-spacing: 0.5px;
            text-decoration: underline;

            @include media('>=tablet') {
                flex-direction: row;
            }
        }

        img {
            margin-right: 20px;
            max-width: 100px;
        }
    }
}

.ips-product-container {
    height: 100%;
    padding: 0 6px;
    width: 100%;
    margin-bottom: 15px;

    @include media('>=phoneLg') {
        padding: 0 10px;
        width: calc(100%/3);
        margin-bottom: 0;
    }

    @include media('>=tablet') {
        padding: 0 15px;
    }

    li {
        margin-bottom: 30px;
    }

    ul {
        @include media('<phoneLg') {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            li {
                width: calc(100%/2);
                display: block;
                padding: 0 6px;
                text-align: center;
            }
        }
    }
}
