@import 'config/variables';
@import 'components/typography/mixin';
@import 'vendors/include-media';

$page-title_font-size: 4rem !default;
$page-title_text-transform: uppercase !default;
$page-title_font-weight: 600 !default;

$page-title_span-color: $color_white !default;
$page-title_span-font-weight: 600 !default;
$page-title_span-padding: 0 !default;

.cs-page-teaser--container {
    position: relative;

    picture {
        top: 0;
        left: 0;
        opacity: 0;
    }

    picture,
    img {
        display: block;
    }

    .cms-page__teaser-wrapper {
        height: 351px;
        @include media('<tablet') {
            height: 200px !important;
        }
    }

    .cms-page__teaser-image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 351px;
        @include media('<tablet') {
            min-height: 200px;
        }
    }

    .page-title-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }

    .cms-page__teaser > picture,
    .cms-page__teaser > img {
        display: none;
        visibility: hidden;
    }

    .cms-page__teaser {
        @include media('>=tablet') {
            &:after {
                content:'';
                display:block;
                background-image: url("../images/icons/arrow-down@1x.svg");
                background-repeat: no-repeat;
                background-size: contain;
                height: 40px;
                width: 40px;
                position: absolute;
                bottom: 20px;
                left: 50%;
                margin-left: -20px;
            }
        }
    }

    @include media('<tablet') {
        .cms-page__teaser-image {
            min-height: 200px !important;
        }
    }
}

.page-title {
    @include headline($level: $page-title_font-size);
    padding-bottom: 1rem;
    text-transform: $page-title_text-transform;
    font-weight: $page-title_font-weight;
    color: $page-title_span-color;

    @include media('<tablet') {
       font-size: 3rem;
        text-align: center;
    }

    &__headline {
        margin: 0;
        padding: 0;
        font-size: inherit;
        font-weight: inherit;
        font-family: inherit;
        display: inline;
    }

    &__span {
        color: $page-title_span-color;
        font-weight: $page-title_span-font-weight;
        padding: $page-title_span-padding;
    }
}

.cms-page-view {
    .page-title {
        padding: 0;
        margin: 0;
    }
}



