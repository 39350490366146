/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* Radio and checkbox styling */
/* stylelint-disable no-descending-specificity */
.cs-field,
.cs-input {
  font-size: 1.3rem;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  border: 0;
  padding: 0;
  margin: 0 0 10px;
  width: 100%; }
  .cs-field__control,
  .cs-input__control {
    width: 100%; }
  .cs-field__label,
  .cs-input__label {
    font-weight: 700;
    font-size: 0.85em;
    width: 100%;
    text-transform: none;
    color: inherit;
    margin: 0 0 3px 0; }
    .required .cs-field__label:after, .required
    .cs-input__label:after {
      content: ' *';
      color: #CC1C3A; }
  .cs-field__input, .cs-field__textarea,
  .cs-input__input,
  .cs-input__textarea {
    font-size: 1.3rem;
    line-height: 1.275em;
    padding: 11px 10px;
    color: #212121;
    background: #ffffff;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    transition: border-color 0.5s;
    margin: 0 0 0.2em;
    width: 100%;
    max-height: 40px;
    background-clip: padding-box;
    font-family: inherit; }
    .cs-field__input:hover:not([disabled]), .cs-field__input:focus:not([disabled]), .cs-field__textarea:hover:not([disabled]), .cs-field__textarea:focus:not([disabled]),
    .cs-input__input:hover:not([disabled]),
    .cs-input__input:focus:not([disabled]),
    .cs-input__textarea:hover:not([disabled]),
    .cs-input__textarea:focus:not([disabled]) {
      border: 1px solid #FF4604;
      box-shadow: none; }
    .cs-field__input::placeholder, .cs-field__textarea::placeholder,
    .cs-input__input::placeholder,
    .cs-input__textarea::placeholder {
      opacity: 1;
      color: #6E6E6E; }
    .cs-field__input[disabled], .cs-field__textarea[disabled],
    .cs-input__input[disabled],
    .cs-input__textarea[disabled] {
      opacity: 0.65; }
    .cs-field__input.mage-error, .cs-field__input.mage-error:hover:not(:focus), .cs-field__textarea.mage-error, .cs-field__textarea.mage-error:hover:not(:focus),
    .cs-input__input.mage-error,
    .cs-input__input.mage-error:hover:not(:focus),
    .cs-input__textarea.mage-error,
    .cs-input__textarea.mage-error:hover:not(:focus) {
      border: 1px solid #CC1C3A; }
  .cs-field__select,
  .cs-input__select {
    display: block;
    font-size: 1.3rem;
    line-height: 1.275em;
    padding: 11px 10px;
    padding-right: 2.3em;
    color: #212121;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    margin: 0 0 0.2em;
    outline: 0;
    appearance: none;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' fill='%23dcdcdc'%3e%3cpath d='M0 1.2C0 .5.6 0 1.2 0c.3 0 .6.1.8.3l6 5.9 6-5.9c.5-.4 1.2-.3 1.7.1.4.4.4 1.1 0 1.5L8.9 8.7c-.5.4-1.2.4-1.7 0L.4 2a.9.9 0 0 1-.4-.8z'/%3e%3c/svg%3e"), linear-gradient(0deg, transparent 0%, transparent 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.8em top 52%, 0 0;
    background-size: 0.7em auto, 100%;
    transition: border-color 0.5s; }
    .cs-field__select:-moz-focusring, .cs-field__select::-moz-focus-inner,
    .cs-input__select:-moz-focusring,
    .cs-input__select::-moz-focus-inner {
      color: transparent !important;
      text-shadow: 0 0 0 #000 !important; }
    .cs-field__select::-ms-expand,
    .cs-input__select::-ms-expand {
      display: none; }
    .cs-field__select:hover:not([disabled]), .cs-field__select:focus:not([disabled]),
    .cs-input__select:hover:not([disabled]),
    .cs-input__select:focus:not([disabled]) {
      border: 1px solid #FF4604;
      box-shadow: none;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' fill='%23FF4604'%3e%3cpath d='M0 1.2C0 .5.6 0 1.2 0c.3 0 .6.1.8.3l6 5.9 6-5.9c.5-.4 1.2-.3 1.7.1.4.4.4 1.1 0 1.5L8.9 8.7c-.5.4-1.2.4-1.7 0L.4 2a.9.9 0 0 1-.4-.8z'/%3e%3c/svg%3e"), linear-gradient(0deg, transparent 0%, transparent 100%); }
      .ie11 .cs-field__select:hover:not([disabled]), .ie11 .cs-field__select:focus:not([disabled]), .ie11
      .cs-input__select:hover:not([disabled]), .ie11
      .cs-input__select:focus:not([disabled]) {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' fill='%23FF4604'%3e%3cpath d='M0 1.2C0 .5.6 0 1.2 0c.3 0 .6.1.8.3l6 5.9 6-5.9c.5-.4 1.2-.3 1.7.1.4.4.4 1.1 0 1.5L8.9 8.7c-.5.4-1.2.4-1.7 0L.4 2a.9.9 0 0 1-.4-.8z'/%3e%3c/svg%3e"); }
    .cs-field__select[disabled],
    .cs-input__select[disabled] {
      opacity: 0.65; }
    .cs-field__select option,
    .cs-input__select option {
      font-weight: 500;
      padding: 0;
      color: initial; }
    .ie11 .cs-field__select, .ie11
    .cs-input__select {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' fill='%23dcdcdc'%3e%3cpath d='M0 1.2C0 .5.6 0 1.2 0c.3 0 .6.1.8.3l6 5.9 6-5.9c.5-.4 1.2-.3 1.7.1.4.4.4 1.1 0 1.5L8.9 8.7c-.5.4-1.2.4-1.7 0L.4 2a.9.9 0 0 1-.4-.8z'/%3e%3c/svg%3e");
      background-size: 0.7em 0.7em, 100%; }
  .cs-field__radio,
  .cs-input__radio {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0;
    font-size: 1.6rem;
    overflow: hidden;
    vertical-align: middle;
    appearance: none;
    flex: 0 0 auto;
    cursor: pointer;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 1em 1em;
    outline: 0;
    border-radius: 50%;
    transition: background-color 0.5s, border-color 0.5s; }
    .cs-field__radio::-ms-check,
    .cs-input__radio::-ms-check {
      color: transparent;
      border: 1px solid #dcdcdc;
      box-shadow: none;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 1em 1em;
      outline: 0;
      border-radius: 50%;
      transition: background-color 0.5s, border-color 0.5s;
      background-color: transparent; }
    .cs-field__radio::-moz-focus-inner, .cs-field__radio:-moz-focusring,
    .cs-input__radio::-moz-focus-inner,
    .cs-input__radio:-moz-focusring {
      color: transparent !important;
      text-shadow: 0 0 0 #000 !important; }
    .cs-field__radio:hover:not([disabled]), .cs-field__radio:focus:not([disabled]),
    .cs-input__radio:hover:not([disabled]),
    .cs-input__radio:focus:not([disabled]) {
      border: 1px solid #FF7442;
      box-shadow: none;
      background-color: transparent; }
    .cs-field__radio:checked,
    .cs-input__radio:checked {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='%23FF4604' r='4' cy='7' cx='7'/%3e%3c/svg%3e"); }
      .cs-field__radio:checked::-ms-check,
      .cs-input__radio:checked::-ms-check {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='%23FF4604' r='4' cy='6.5' cx='6.5'/%3e%3c/svg%3e"); }
      .cs-field__radio:checked, .cs-field__radio:checked:hover, .cs-field__radio:checked:focus,
      .cs-input__radio:checked,
      .cs-input__radio:checked:hover,
      .cs-input__radio:checked:focus {
        font-size: 0;
        border: 6px solid #FF4604;
        background-color: transparent;
        box-shadow: none; }
    .cs-field__radio[disabled],
    .cs-field__radio[disabled] + .label,
    .cs-field__radio[disabled] + .cs-input__label,
    .cs-input__radio[disabled],
    .cs-input__radio[disabled] + .label,
    .cs-input__radio[disabled] + .cs-input__label {
      opacity: 0.65; }
  .cs-field__checkbox,
  .cs-input__checkbox {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0;
    font-size: 1.6rem;
    overflow: hidden;
    vertical-align: middle;
    appearance: none;
    flex: 0 0 auto;
    cursor: pointer;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 1em 1em;
    outline: 0;
    border-radius: 50%;
    transition: background-color 0.5s, border-color 0.5s;
    border-radius: 0;
    background-size: 1em 1em;
    margin-right: 15px; }
    .cs-field__checkbox::-ms-check,
    .cs-input__checkbox::-ms-check {
      color: transparent;
      border: 1px solid #dcdcdc;
      box-shadow: none;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 1em 1em;
      outline: 0;
      border-radius: 50%;
      transition: background-color 0.5s, border-color 0.5s;
      background-color: transparent; }
    .cs-field__checkbox::-moz-focus-inner, .cs-field__checkbox:-moz-focusring,
    .cs-input__checkbox::-moz-focus-inner,
    .cs-input__checkbox:-moz-focusring {
      color: transparent !important;
      text-shadow: 0 0 0 #000 !important; }
    .cs-field__checkbox:hover:not([disabled]), .cs-field__checkbox:focus:not([disabled]),
    .cs-input__checkbox:hover:not([disabled]),
    .cs-input__checkbox:focus:not([disabled]) {
      border: 1px solid #FF7442;
      box-shadow: none;
      background-color: transparent; }
    .cs-field__checkbox:checked,
    .cs-input__checkbox:checked {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='%23FF4604' r='4' cy='7' cx='7'/%3e%3c/svg%3e"); }
      .cs-field__checkbox:checked::-ms-check,
      .cs-input__checkbox:checked::-ms-check {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='%23FF4604' r='4' cy='6.5' cx='6.5'/%3e%3c/svg%3e"); }
      .cs-field__checkbox:checked, .cs-field__checkbox:checked:hover, .cs-field__checkbox:checked:focus,
      .cs-input__checkbox:checked,
      .cs-input__checkbox:checked:hover,
      .cs-input__checkbox:checked:focus {
        font-size: 0;
        border: 6px solid #FF4604;
        background-color: transparent;
        box-shadow: none; }
    .cs-field__checkbox[disabled],
    .cs-field__checkbox[disabled] + .label,
    .cs-field__checkbox[disabled] + .cs-input__label,
    .cs-input__checkbox[disabled],
    .cs-input__checkbox[disabled] + .label,
    .cs-input__checkbox[disabled] + .cs-input__label {
      opacity: 0.65; }
    .cs-field__checkbox::-ms-check,
    .cs-input__checkbox::-ms-check {
      color: transparent;
      border-radius: 0;
      background-size: 1em 1em; }
    .cs-field__checkbox:hover,
    .cs-input__checkbox:hover {
      border: 1px solid #FF4604; }
    .cs-field__checkbox:checked,
    .cs-input__checkbox:checked {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%23FF4604' d='M5.313 10.108H5.24a1.064 1.005 0 0 1-.75-.41l-1.947-2.55a1.064 1.005 0 0 1 1.723-1.175L5.463 7.56 9.06 4.19a1.064 1.005 0 0 1 .75-.297h.846a1.064 1.005 0 0 1 0 2.004h-.45L6.064 9.81a1.064 1.005 0 0 1-.75.298z'/%3e%3c/svg%3e");
      border: 1px solid #FF4604;
      font-size: 16px; }
      .cs-field__checkbox:checked::-ms-check,
      .cs-input__checkbox:checked::-ms-check {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%23FF4604' d='M5.313 10.108H5.24a1.064 1.005 0 0 1-.75-.41l-1.947-2.55a1.064 1.005 0 0 1 1.723-1.175L5.463 7.56 9.06 4.19a1.064 1.005 0 0 1 .75-.297h.846a1.064 1.005 0 0 1 0 2.004h-.45L6.064 9.81a1.064 1.005 0 0 1-.75.298z'/%3e%3c/svg%3e"); }
      .cs-field__checkbox:checked:focus, .cs-field__checkbox:checked:hover,
      .cs-input__checkbox:checked:focus,
      .cs-input__checkbox:checked:hover {
        font-size: 16px;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%23FF4604' d='M5.313 10.108H5.24a1.064 1.005 0 0 1-.75-.41l-1.947-2.55a1.064 1.005 0 0 1 1.723-1.175L5.463 7.56 9.06 4.19a1.064 1.005 0 0 1 .75-.297h.846a1.064 1.005 0 0 1 0 2.004h-.45L6.064 9.81a1.064 1.005 0 0 1-.75.298z'/%3e%3c/svg%3e");
        border: 1px solid #FF4604; }
    .cs-field__checkbox.mage-error, .cs-field__checkbox.mage-error:hover:not(:focus),
    .cs-input__checkbox.mage-error,
    .cs-input__checkbox.mage-error:hover:not(:focus) {
      border: 1px solid #CC1C3A; }
  .cs-field__icon,
  .cs-input__icon {
    position: absolute;
    width: 1.3em;
    height: 1.3em;
    right: 0.8em;
    top: 0.8em;
    display: none; }
  .cs-field__note,
  .cs-input__note {
    display: block;
    font-size: 0.85em;
    padding: 0.3em 0 0;
    color: #696969; }
  .cs-field__error,
  .cs-input__error {
    display: block;
    font-size: 0.85em;
    padding: 0.3em 0 0;
    color: #696969;
    color: #CC1C3A; }

.field {
  font-size: 1.3rem;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  border: 0;
  padding: 0;
  margin: 0 0 10px;
  width: 100%; }
  .field.note {
    font-size: 1.6rem;
    letter-spacing: 0.5px; }
  .field._success:not(._warn) .control {
    position: relative; }
    .field._success:not(._warn) .control:after {
      position: absolute;
      width: 1.3em;
      height: 1.3em;
      right: 0.8em;
      top: 0.8em;
      display: none;
      background: url("../images/check-green.svg") no-repeat center center/contain;
      content: ''; }
  .field .control {
    width: 100%; }
    .field .control > textarea {
      font-size: 1.3rem;
      line-height: 1.275em;
      padding: 11px 10px;
      color: #212121;
      background: #ffffff;
      border-radius: 3px;
      border: 1px solid #dcdcdc;
      box-shadow: none;
      transition: border-color 0.5s;
      margin: 0 0 0.2em;
      width: 100%;
      max-height: 40px;
      background-clip: padding-box;
      font-family: inherit; }
      .field .control > textarea:hover:not([disabled]), .field .control > textarea:focus:not([disabled]) {
        border: 1px solid #FF4604;
        box-shadow: none; }
      .field .control > textarea::placeholder {
        opacity: 1;
        color: #6E6E6E; }
      .field .control > textarea[disabled] {
        opacity: 0.65; }
  .field .label {
    font-weight: 700;
    font-size: 0.85em;
    width: 100%;
    text-transform: none;
    color: inherit;
    margin: 0 0 3px 0; }
  .field.required > .label:after, .field._required > .label:after {
    content: ' *';
    color: #CC1C3A; }
  .field.required ._required > .label:after {
    display: none; }
  .field .input-text {
    font-size: 1.3rem;
    line-height: 1.275em;
    padding: 11px 10px;
    color: #212121;
    background: #ffffff;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    transition: border-color 0.5s;
    margin: 0 0 0.2em;
    width: 100%;
    max-height: 40px;
    background-clip: padding-box;
    font-family: inherit; }
    .field .input-text:hover:not([disabled]), .field .input-text:focus:not([disabled]) {
      border: 1px solid #FF4604;
      box-shadow: none; }
    .field .input-text::placeholder {
      opacity: 1;
      color: #6E6E6E; }
    .field .input-text[disabled] {
      opacity: 0.65; }
    .field .input-text.mage-error, .field .input-text.mage-error:hover:not(:focus) {
      border: 1px solid #CC1C3A; }
    .field .input-text.required-entry {
      margin-bottom: 1rem; }
  .field .select {
    display: block;
    font-size: 1.3rem;
    line-height: 1.275em;
    padding: 11px 10px;
    padding-right: 2.3em;
    color: #212121;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    margin: 0 0 0.2em;
    outline: 0;
    appearance: none;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' fill='%23dcdcdc'%3e%3cpath d='M0 1.2C0 .5.6 0 1.2 0c.3 0 .6.1.8.3l6 5.9 6-5.9c.5-.4 1.2-.3 1.7.1.4.4.4 1.1 0 1.5L8.9 8.7c-.5.4-1.2.4-1.7 0L.4 2a.9.9 0 0 1-.4-.8z'/%3e%3c/svg%3e"), linear-gradient(0deg, transparent 0%, transparent 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.8em top 52%, 0 0;
    background-size: 0.7em auto, 100%;
    transition: border-color 0.5s; }
    .field .select:-moz-focusring, .field .select::-moz-focus-inner {
      color: transparent !important;
      text-shadow: 0 0 0 #000 !important; }
    .field .select::-ms-expand {
      display: none; }
    .field .select:hover:not([disabled]), .field .select:focus:not([disabled]) {
      border: 1px solid #FF4604;
      box-shadow: none;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' fill='%23FF4604'%3e%3cpath d='M0 1.2C0 .5.6 0 1.2 0c.3 0 .6.1.8.3l6 5.9 6-5.9c.5-.4 1.2-.3 1.7.1.4.4.4 1.1 0 1.5L8.9 8.7c-.5.4-1.2.4-1.7 0L.4 2a.9.9 0 0 1-.4-.8z'/%3e%3c/svg%3e"), linear-gradient(0deg, transparent 0%, transparent 100%); }
      .ie11 .field .select:hover:not([disabled]), .ie11 .field .select:focus:not([disabled]) {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' fill='%23FF4604'%3e%3cpath d='M0 1.2C0 .5.6 0 1.2 0c.3 0 .6.1.8.3l6 5.9 6-5.9c.5-.4 1.2-.3 1.7.1.4.4.4 1.1 0 1.5L8.9 8.7c-.5.4-1.2.4-1.7 0L.4 2a.9.9 0 0 1-.4-.8z'/%3e%3c/svg%3e"); }
    .field .select[disabled] {
      opacity: 0.65; }
    .field .select option {
      font-weight: 500;
      padding: 0;
      color: initial; }
    .ie11 .field .select {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' fill='%23dcdcdc'%3e%3cpath d='M0 1.2C0 .5.6 0 1.2 0c.3 0 .6.1.8.3l6 5.9 6-5.9c.5-.4 1.2-.3 1.7.1.4.4.4 1.1 0 1.5L8.9 8.7c-.5.4-1.2.4-1.7 0L.4 2a.9.9 0 0 1-.4-.8z'/%3e%3c/svg%3e");
      background-size: 0.7em 0.7em, 100%; }
  .field .checkbox {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0;
    font-size: 1.6rem;
    overflow: hidden;
    vertical-align: middle;
    appearance: none;
    flex: 0 0 auto;
    cursor: pointer;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 1em 1em;
    outline: 0;
    border-radius: 50%;
    transition: background-color 0.5s, border-color 0.5s;
    border-radius: 0;
    background-size: 1em 1em;
    margin-right: 15px; }
    .field .checkbox::-ms-check {
      color: transparent;
      border: 1px solid #dcdcdc;
      box-shadow: none;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 1em 1em;
      outline: 0;
      border-radius: 50%;
      transition: background-color 0.5s, border-color 0.5s;
      background-color: transparent; }
    .field .checkbox::-moz-focus-inner, .field .checkbox:-moz-focusring {
      color: transparent !important;
      text-shadow: 0 0 0 #000 !important; }
    .field .checkbox:hover:not([disabled]), .field .checkbox:focus:not([disabled]) {
      border: 1px solid #FF7442;
      box-shadow: none;
      background-color: transparent; }
    .field .checkbox:checked {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='%23FF4604' r='4' cy='7' cx='7'/%3e%3c/svg%3e"); }
      .field .checkbox:checked::-ms-check {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='%23FF4604' r='4' cy='6.5' cx='6.5'/%3e%3c/svg%3e"); }
      .field .checkbox:checked, .field .checkbox:checked:hover, .field .checkbox:checked:focus {
        font-size: 0;
        border: 6px solid #FF4604;
        background-color: transparent;
        box-shadow: none; }
    .field .checkbox[disabled],
    .field .checkbox[disabled] + .label,
    .field .checkbox[disabled] + .cs-input__label {
      opacity: 0.65; }
    .field .checkbox::-ms-check {
      color: transparent;
      border-radius: 0;
      background-size: 1em 1em; }
    .field .checkbox:hover {
      border: 1px solid #FF4604; }
    .field .checkbox:checked {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%23FF4604' d='M5.313 10.108H5.24a1.064 1.005 0 0 1-.75-.41l-1.947-2.55a1.064 1.005 0 0 1 1.723-1.175L5.463 7.56 9.06 4.19a1.064 1.005 0 0 1 .75-.297h.846a1.064 1.005 0 0 1 0 2.004h-.45L6.064 9.81a1.064 1.005 0 0 1-.75.298z'/%3e%3c/svg%3e");
      border: 1px solid #FF4604;
      font-size: 16px; }
      .field .checkbox:checked::-ms-check {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%23FF4604' d='M5.313 10.108H5.24a1.064 1.005 0 0 1-.75-.41l-1.947-2.55a1.064 1.005 0 0 1 1.723-1.175L5.463 7.56 9.06 4.19a1.064 1.005 0 0 1 .75-.297h.846a1.064 1.005 0 0 1 0 2.004h-.45L6.064 9.81a1.064 1.005 0 0 1-.75.298z'/%3e%3c/svg%3e"); }
      .field .checkbox:checked:focus, .field .checkbox:checked:hover {
        font-size: 16px;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%23FF4604' d='M5.313 10.108H5.24a1.064 1.005 0 0 1-.75-.41l-1.947-2.55a1.064 1.005 0 0 1 1.723-1.175L5.463 7.56 9.06 4.19a1.064 1.005 0 0 1 .75-.297h.846a1.064 1.005 0 0 1 0 2.004h-.45L6.064 9.81a1.064 1.005 0 0 1-.75.298z'/%3e%3c/svg%3e");
        border: 1px solid #FF4604; }
    .field .checkbox.mage-error, .field .checkbox.mage-error:hover:not(:focus) {
      border: 1px solid #CC1C3A; }
  .field .radio {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0;
    font-size: 1.6rem;
    overflow: hidden;
    vertical-align: middle;
    appearance: none;
    flex: 0 0 auto;
    cursor: pointer;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 1em 1em;
    outline: 0;
    border-radius: 50%;
    transition: background-color 0.5s, border-color 0.5s; }
    .field .radio::-ms-check {
      color: transparent;
      border: 1px solid #dcdcdc;
      box-shadow: none;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 1em 1em;
      outline: 0;
      border-radius: 50%;
      transition: background-color 0.5s, border-color 0.5s;
      background-color: transparent; }
    .field .radio::-moz-focus-inner, .field .radio:-moz-focusring {
      color: transparent !important;
      text-shadow: 0 0 0 #000 !important; }
    .field .radio:hover:not([disabled]), .field .radio:focus:not([disabled]) {
      border: 1px solid #FF7442;
      box-shadow: none;
      background-color: transparent; }
    .field .radio:checked {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='%23FF4604' r='4' cy='7' cx='7'/%3e%3c/svg%3e"); }
      .field .radio:checked::-ms-check {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='%23FF4604' r='4' cy='6.5' cx='6.5'/%3e%3c/svg%3e"); }
      .field .radio:checked, .field .radio:checked:hover, .field .radio:checked:focus {
        font-size: 0;
        border: 6px solid #FF4604;
        background-color: transparent;
        box-shadow: none; }
    .field .radio[disabled],
    .field .radio[disabled] + .label,
    .field .radio[disabled] + .cs-input__label {
      opacity: 0.65; }
  .field.choice {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center; }
    .field.choice label {
      margin: 0;
      flex: 1;
      font-weight: normal;
      font-size: 1.6rem; }
    .field.choice div.mage-error {
      width: 100%; }
    .field.choice input[type="radio"] + label {
      margin-left: 10px; }
  .field.customer-name-prefix select {
    display: block;
    font-size: 1.3rem;
    line-height: 1.275em;
    padding: 11px 10px;
    padding-right: 2.3em;
    color: #212121;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    margin: 0 0 0.2em;
    outline: 0;
    appearance: none;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' fill='%23dcdcdc'%3e%3cpath d='M0 1.2C0 .5.6 0 1.2 0c.3 0 .6.1.8.3l6 5.9 6-5.9c.5-.4 1.2-.3 1.7.1.4.4.4 1.1 0 1.5L8.9 8.7c-.5.4-1.2.4-1.7 0L.4 2a.9.9 0 0 1-.4-.8z'/%3e%3c/svg%3e"), linear-gradient(0deg, transparent 0%, transparent 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.8em top 52%, 0 0;
    background-size: 0.7em auto, 100%;
    transition: border-color 0.5s; }
    .field.customer-name-prefix select:-moz-focusring, .field.customer-name-prefix select::-moz-focus-inner {
      color: transparent !important;
      text-shadow: 0 0 0 #000 !important; }
    .field.customer-name-prefix select::-ms-expand {
      display: none; }
    .field.customer-name-prefix select:hover:not([disabled]), .field.customer-name-prefix select:focus:not([disabled]) {
      border: 1px solid #FF4604;
      box-shadow: none;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' fill='%23FF4604'%3e%3cpath d='M0 1.2C0 .5.6 0 1.2 0c.3 0 .6.1.8.3l6 5.9 6-5.9c.5-.4 1.2-.3 1.7.1.4.4.4 1.1 0 1.5L8.9 8.7c-.5.4-1.2.4-1.7 0L.4 2a.9.9 0 0 1-.4-.8z'/%3e%3c/svg%3e"), linear-gradient(0deg, transparent 0%, transparent 100%); }
      .ie11 .field.customer-name-prefix select:hover:not([disabled]), .ie11 .field.customer-name-prefix select:focus:not([disabled]) {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' fill='%23FF4604'%3e%3cpath d='M0 1.2C0 .5.6 0 1.2 0c.3 0 .6.1.8.3l6 5.9 6-5.9c.5-.4 1.2-.3 1.7.1.4.4.4 1.1 0 1.5L8.9 8.7c-.5.4-1.2.4-1.7 0L.4 2a.9.9 0 0 1-.4-.8z'/%3e%3c/svg%3e"); }
    .field.customer-name-prefix select[disabled] {
      opacity: 0.65; }
    .field.customer-name-prefix select option {
      font-weight: 500;
      padding: 0;
      color: initial; }
    .ie11 .field.customer-name-prefix select {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' fill='%23dcdcdc'%3e%3cpath d='M0 1.2C0 .5.6 0 1.2 0c.3 0 .6.1.8.3l6 5.9 6-5.9c.5-.4 1.2-.3 1.7.1.4.4.4 1.1 0 1.5L8.9 8.7c-.5.4-1.2.4-1.7 0L.4 2a.9.9 0 0 1-.4-.8z'/%3e%3c/svg%3e");
      background-size: 0.7em 0.7em, 100%; }
  .field._error .input-text, .field._error .input-text:hover:not(:focus),
  .field._error .checkbox,
  .field._error .checkbox:hover:not(:focus) {
    border: 1px solid #CC1C3A; }
  .field .control._with-tooltip input {
    width: 100%;
    margin-right: 0; }
  .field .field-tooltip {
    position: static; }
    .field .field-tooltip .label,
    .field .field-tooltip .field-tooltip-action {
      display: none; }
    .field .field-tooltip .field-tooltip-content {
      position: static;
      background: none;
      border: none;
      display: block;
      font-size: 0.85em;
      padding: 0.3em 0 0;
      color: #696969; }
      .field .field-tooltip .field-tooltip-content:before, .field .field-tooltip .field-tooltip-content:after {
        display: none; }
  .field .note {
    display: block;
    font-size: 0.85em;
    padding: 0.3em 0 0;
    color: #696969; }

div.mage-error,
div.field-error {
  display: block;
  font-size: 0.85em;
  padding: 0.3em 0 0;
  color: #696969;
  color: #CC1C3A; }

.opc-wrapper .select-wrap,
.estimate .select-wrap {
  position: relative; }
  .opc-wrapper .select-wrap select,
  .estimate .select-wrap select {
    padding-right: 50px; }
  .opc-wrapper .select-wrap:after,
  .estimate .select-wrap:after {
    content: '';
    background-color: #000;
    display: inline-block;
    position: absolute;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background-image: url("../images/icons/white-arrow.svg");
    width: 44px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center;
    top: 0;
    right: 0;
    cursor: pointer;
    pointer-events: none; }

small.required-fields {
  display: block;
  text-align: right;
  font-size: 1.2rem;
  color: #6E6E6E; }
  small.required-fields span {
    color: #CC1C3A; }

/* stylelint-enable */
