/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-links-block-addto {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%; }
  .page-product-bundle .cs-links-block-addto {
    margin-top: 1rem; }
  .cs-product-tile__addon .cs-links-block-addto {
    padding-top: 0; }
  .cs-links-block-addto--centered {
    justify-content: center; }
  .cs-links-block-addto__item {
    margin: 0; }
    .cs-links-block-addto__item + .cs-links-block-addto__item {
      margin-top: 0.5rem; }
    .cs-links-block-addto__item:nth-child(2n) {
      text-align: right; }
      .cs-links-block-addto__item:nth-child(2n) .cs-links-block-addto__icon {
        order: 2;
        margin-left: 0.5rem; }
  .cs-links-block-addto__link {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #FF7442;
    transition: color 0.15s linear; }
    .cs-links-block-addto__link:hover {
      color: #FF7442;
      text-decoration: none; }
  .cs-links-block-addto__icon {
    width: 1.375em;
    height: 1.375em;
    margin-right: 0.4rem; }
    .cs-links-block-addto__icon path {
      fill: #6E6E6E; }
    .cs-links-block-addto__icon--wishlist path {
      fill: #CC1C3A; }
    .cs-links-block-addto__icon--compare path {
      fill: #FF4604; }
