@import 'utils/get-value-from-list';
@import 'components/button/animation';
@import 'components/button/hook';

// Define default type for buttons. You can override it individually per button in each component
// Variants: primary | secondary | link | success | error
$button_default-type: 'primary' !default;

// Define default position for icons in button. You can override it individually per button in each component
// Variants: left | right | no-icon | only-icon
$button_default-icon-pos: 'right' !default;

// General styles for buttons
$button_font-size: 1.4rem !default;
$button_font-weight: 700 !default;
$button_border-size: 1px !default;
$button_padding: 0.75em 1em !default;
$button_padding-when-icon: 3em !default;
$button_line-height: 1.275em !default;
$button_border-radius: 25px !default;
$button_animation-time: 0.5s !default;
$button_text-transform: uppercase !default;
$button_text-letter-spacing: normal !default;
$button_text-align: center !default;

$button_size-default: 'regular' !default;

// Styles for `add to cart` button type
$button_addto-border: $button_border-size solid transparent !default;
$button_addto--hover-border: $button_border-size solid transparent !default;
$button_addto-background: $color_add_to_cart !default;
$button_addto--hover-background: #4980C1 !default;
$button_addto-color: $color_white !default;
$button_addto--hover-color: $color_white !default;
$button_addto-fill: $color_white !default;
$button_addto--hover-fill: $color_white !default;
$button_addto-enable-animation: false !default;
$button_addto-border-radius: 25px !default;
$button_addto-font-size: 1.6rem !default;

// Styles for `primary` button type
$button_primary-border: $button_border-size solid transparent !default;
$button_primary--hover-border: $button_border-size solid transparent !default;
$button_primary-background: $color_primary-500 !default;
$button_primary--hover-background: $color_primary-800 !default;
$button_primary-color: $color_text-200 !default;
$button_primary--hover-color: $color_text-200 !default;
$button_primary-fill: $color_text-200 !default;
$button_primary--hover-fill: $color_text-200 !default;
$button_primary-enable-animation: false !default;
$button_primary--disabled-background: $button_primary--hover-background !default;

// Styles for `secondary` button type
$button_secondary-border: $button_border-size solid transparent !default;
$button_secondary--hover-border: $button_border-size solid transparent !default;
$button_secondary-background: $color_black !default;
$button_secondary--hover-background: $color_body-text !default;
$button_secondary-color: $color_white !default;
$button_secondary--hover-color: $color_white !default;
$button_secondary-fill: $color_primary-500 !default;
$button_secondary--hover-fill: $color_text-200 !default;
$button_secondary-enable-animation: false !default;

// Style for `link` button type
$button_link-color: $color_primary-500 !default;
$button_link--hover-color: $color_primary-800 !default;
$button_link-icon-padding: 2rem !default;

// Styles for `success` button type
$button_success-border: $button_border-size solid transparent !default;
$button_success--hover-border: $button_border-size solid transparent !default;
$button_success-background: $color_success-500 !default;
$button_success--hover-background: $color_success-600 !default;
$button_success-color: $color_text-200 !default;
$button_success--hover-color: $color_text-200 !default;
$button_success-fill: $color_text-200 !default;
$button_success--hover-fill: $color_text-200 !default;
$button_success-enable-animation: false !default;

// Styles for `error` button type
$button_error-border: $button_border-size solid transparent !default;
$button_error--hover-border: $button_border-size solid transparent !default;
$button_error-background: $color_error-500 !default;
$button_error--hover-background: $color_error-600 !default;
$button_error-color: $color_text-200 !default;
$button_error--hover-color: $color_text-200 !default;
$button_error-fill: $color_text-200 !default;
$button_error--hover-fill: $color_text-200 !default;
$button_error-enable-animation: false !default;

// Defines how much button icon should be pulled vertically to center it.
// Should be defined in em to make it relative to button's font size.
$button_span-font-offset: 0 !default;

// Defines how much button icon should be pulled vertically to center it on icon button.
// Should be defined in em to make it relative to button's font size.
$button_icon-offset: 0.1em !default;

// stylelint-disable function-calc-no-unspaced-operator

@mixin button(
    $type: $button_default-type,
    $icon_pos: $button_default-icon-pos,
    $text_transform: $button_text-transform,
    $border-radius: $button_border-radius
) {
    display: inline-block;
    font-size: $button_font-size;
    font-weight: $button_font-weight;
    text-align: $button_text-align;
    position: relative;
    line-height: $button_line-height;
    margin: 0;
    text-transform: $text_transform;
    border-radius: $border-radius;
    font-family: $font_family-primary;
    padding: 11px 25px;
    font-size: 1.2rem;

    @if ($type != 'link') {
        box-sizing: border-box;
        transition: background-color $button_animation-time,
        border-color $button_animation-time, fill $button_animation-time,
        color $button_animation-time;
        vertical-align: middle;
        z-index: 5;
        will-change: transform, background-color, border-color, fill, color;
    }

    @if ($type == 'addto') {
        @if ($button_primary-enable-animation) {
            @include button_animation(
                $type: 'primary',
                $color_start: $button_addto-background,
                $color_end: $button_addto--hover-background,
                $icon_pos: $icon_pos
            );
        }
    }

    @if ($type == 'primary') {
        @if ($button_primary-enable-animation) {
            @include button_animation(
                $type: 'primary',
                $color_start: $button_primary-background,
                $color_end: $button_primary--hover-background,
                $icon_pos: $icon_pos
            );
        }
    }

    @if ($type == 'secondary') {
        @if ($button_secondary-enable-animation) {
            @include button_animation(
                $type: 'secondary',
                $color_start: $button_secondary-background,
                $color_end: $button_secondary--hover-background,
                $icon_pos: $icon_pos
            );
        }
    }

    @if ($type == 'link') {
        transition: color $button_animation-time, fill $button_animation-time;
        will-change: fill, color;

        * {
            transition: color $button_animation-time,
            fill $button_animation-time;
            will-change: fill, color;
        }
    }

    @if ($type == 'success') {
        @if ($button_success-enable-animation) {
            @include button_animation(
                $type: 'success',
                $color_start: $button_success-background,
                $color_end: $button_success--hover-background,
                $icon_pos: $icon_pos
            );
        }
    }

    @if ($type == 'error') {
        @if ($button_error-enable-animation) {
            @include button_animation(
                $type: 'error',
                $color_start: $button_error-background,
                $color_end: $button_error--hover-background,
                $icon_pos: $icon_pos
            );
        }
    }

    &.medium {
        padding: 11px 25px;
        font-size: 1.4rem;
    }
    &.large {
        padding: 13px 25px;
        font-size: 1.6rem;
    }

    // stylelint-disable no-descending-specificity
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
        margin: 0;
    }

    &,
    &:visited {
        @if ($type != 'link') {
            cursor: pointer;
            border-radius: $border-radius;
            outline-offset: 1px;
            text-decoration: none;
            box-shadow: none;
        }

        @if ($type == 'addto') {
            border: $button_addto-border;
            color: $button_addto-color;
            background-color: $button_addto-background;
            border-radius: $button_addto-border-radius;
            font-size: $button_addto-font-size;
            position: relative;
            span {
                position: relative;
                z-index: 3;
            }

            &:before {
                background-color: $button_addto-background;
                border: 1px solid $button_addto-background;
                height: 100%;
                content: '';
                display: block;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: $button_addto-border-radius;
                z-index: 2;
            }

            &:after {
                background-color: $button_addto--hover-background;
                height: 100%;
                content: '';
                display: block;
                width: 100%;
                position: absolute;
                top: 4px;
                left: 0;
                border-radius: $button_addto-border-radius;
                z-index: 1;
            }

            * {
                color: $button_addto-color;
                fill: $button_addto-fill;
            }
        }

        @if ($type == 'primary') {
            border: $button_primary-border;
            color: $button_primary-color;
            background-color: $button_primary-background;

            * {
                color: $button_primary-color;
                fill: $button_primary-fill;
            }
        }

        @if ($type == 'secondary') {
            border: $button_secondary-border;
            color: $button_secondary-color;
            background-color: $button_secondary-background;

            * {
                color: $button_secondary-color;
                fill: $button_secondary-fill;
            }
        }

        @if ($type == 'link') {
            color: $button_link-color;

            * {
                color: $button_link-color;
                fill: $button_link-color;
            }
        }

        @if ($type == 'success') {
            border: $button_success-border;
            color: $button_success-color;
            background-color: $button_success-background;

            * {
                color: $button_success-color;
                fill: $button_success-fill;
            }
        }

        @if ($type == 'error') {
            border: $button_error-border;
            color: $button_error-color;
            background-color: $button_error-background;

            * {
                color: $button_error-color;
                fill: $button_error-fill;
            }
        }
    }
    // stylelint-enable no-descending-specificity

    &:hover,
    &:focus,
    &:active {
        &:not([disabled]) {
            @if ($type != 'link') {
                box-shadow: none;
                outline: none;
                text-decoration: none;
            }

            @if ($type == 'addto') {
                border: $button_addto--hover-border;
                color: $button_addto--hover-color;
                background-color: $button_addto--hover-background;

                &:after {
                    top: 2px;
                }

                &:before {
                    border: 1px solid $button_addto--hover-background;
                    background-color: $button_addto--hover-background;
                }

                * {
                    color: $button_addto--hover-color;
                    fill: $button_addto--hover-fill;
                }
            }

            @if ($type == 'primary') {
                border: $button_primary--hover-border;
                color: $button_primary--hover-color;
                background-color: $button_primary--hover-background;

                * {
                    color: $button_primary--hover-color;
                    fill: $button_primary--hover-fill;
                }
            }

            @if ($type == 'secondary') {
                border: $button_secondary--hover-border;
                color: $button_secondary--hover-color;
                background-color: $button_secondary--hover-background;

                * {
                    color: $button_secondary--hover-color;
                    fill: $button_secondary--hover-fill;
                }
            }

            @if ($type == 'link') {
                color: $button_link--hover-color;

                * {
                    color: $button_link--hover-color;
                    fill: $button_link--hover-color;
                }
            }

            @if ($type == 'success') {
                border: $button_success--hover-border;
                color: $button_success--hover-color;
                background-color: $button_success--hover-background;

                * {
                    color: $button_success--hover-color;
                    fill: $button_success--hover-fill;
                }
            }

            @if ($type == 'error') {
                border: $button_error--hover-border;
                color: $button_error--hover-color;
                background-color: $button_error--hover-background;

                * {
                    color: $button_error--hover-color;
                    fill: $button_error--hover-fill;
                }
            }
        }
    }

    &[disabled] {
        cursor: auto;
        background-color: #a6e5b5;
        position: relative;

        span.cs-header-search__button-label {
            z-index: 2;
            position: relative;
        }

        @if ($type == 'addto') {
            border: $button_addto-border;
            color: $button_addto-color;
            background-color: $form_field-border;
            border-radius: $button_addto-border-radius;
            font-size: $button_addto-font-size;
            position: relative;
            span {
                position: relative;
                z-index: 3;
            }

            &:before {
                background-color: $form_field-border;
                border: 1px solid $form_field-border;
                height: 100%;
                content: '';
                display: block;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: $button_addto-border-radius;
                z-index: 2;
            }

            &:after {
                background-color: $form_field-border;
                height: 100%;
                content: '';
                display: block;
                width: 100%;
                position: absolute;
                top: 4px;
                left: 0;
                border-radius: $button_addto-border-radius;
                z-index: 1;
            }
            * {
                color: $button_addto-color;
                fill: $button_addto-fill;
            }
        }
    }

    @include button-hook($type, $icon_pos);
}

@mixin button_span(
    $type: $button_default-type,
    $icon_pos: $button_default-icon-pos
) {
    margin-top: $button_span-font-offset;
    z-index: 1;
    font-weight: $button_font-weight;
    vertical-align: baseline;
    display: inline-block;
    line-height: $button_line-height;
    letter-spacing: $button_text-letter-spacing;

    @if ($icon_pos == 'right') {
        @if ($type == 'link') {
            padding-right: $button_link-icon-padding;
        } @else {
            @if (
                unit($button_padding-when-icon) ==
                    unit(
                        get-value-from-list(
                            $variable: $button_padding,
                            $side: $icon_pos
                        )
                    )
            ) {
                padding-right: $button_padding-when-icon +
                    get-value-from-list(
                        $variable: $button_padding,
                        $side: $icon_pos
                    );
            } @else {
                padding-right: calc(
                    #{$button_padding-when-icon} +
                    #{get-value-from-list(
                        $variable: $button_padding,
                        $side: $icon_pos
                    )}
                );
            }
        }
    } @else if ($icon_pos == 'left') {
        @if ($type == 'link') {
            padding-left: $button_link-icon-padding;
        } @else {
            @if (
                unit($button_padding-when-icon) ==
                    unit(
                        get-value-from-list(
                            $variable: $button_padding,
                            $side: $icon_pos
                        )
                    )
            ) {
                padding-left: $button_padding-when-icon +
                    get-value-from-list(
                        $variable: $button_padding,
                        $side: $icon_pos
                    );
            } @else {
                padding-left: calc(
                    #{$button_padding-when-icon} +
                    #{get-value-from-list(
                        $variable: $button_padding,
                        $side: $icon_pos
                    )}
                );
            }
        }
    } @else if ($icon_pos == 'only-icon') {
        display: none;
    }

    @include button_span-hook($type, $icon_pos);
}

@mixin button_icon(
    $type: $button_default-type,
    $icon_pos: $button_default-icon-pos
) {
    max-width: 1em;
    max-height: $button_font-size;
    z-index: 2;
    height: auto;
    width: 100%;

    @if ($icon_pos == 'right') or ($icon_pos == 'left') {
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
    }

    @if ($icon_pos == 'only-icon') {
        position: relative;
        top: $button_icon-offset;

        @if ($type == 'link') {
            margin-left: $button_link-icon-padding / 2;
            margin-right: $button_link-icon-padding / 2;
        }
    } @else if ($icon_pos == 'right') {
        @if ($type == 'link') {
            right: 0;
        } @else {
            margin-right: 0;
            right: get-value-from-list(
                $variable: $button_padding,
                $side: $icon_pos
            );
        }
    } @else if ($icon_pos == 'left') {
        @if ($type == 'link') {
            left: 0;
        } @else {
            margin-left: 0;
            left: get-value-from-list(
                $variable: $button_padding,
                $side: $icon_pos
            );
        }
    } @else {
        display: none;
    }

    @include button_icon-hook($type, $icon_pos);
}
