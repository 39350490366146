@import 'config/variables';
@import 'vendors/include-media';

@import 'components/qty-counter/mixin';

$header-user-nav_items: 3 !default; // number of items in header user nav (without search item)

$header-user-nav_icon-color: $color_primary-500 !default;
$header-user-nav_icon-color-hover: $color_primary-500 !default;
$header-user-nav_icon-width: 2.6rem !default;
$header-user-nav_icon-height: $header-user-nav_icon-width !default;

// Set up when label in header user nav should be visible
// Supported values: true (always) | false (never) | breakpoint
$header-user-nav_label-visible: '>=tablet' !default;
$header-user-nav_label-font-size: 15px !default;
$header-user-nav_label-font-weight: 600 !default;
$header-user-nav_label-text-transform: uppercase !default;
$header-user-nav_label-color: $color_white !default;
$header-user-nav_label-color-hover: $color_primary-500 !default;
$header-user-nav_label-margin-top: 0.3rem !default;

$header-user-nav_qty-counter-wishlist-show-empty: false !default;
$header-user-nav_qty-counter-minicart-show-empty: false !default;
$header-user-nav_qty-counter-animation-show: true !default;
$header-user-nav_qty-counter-animation-time: $transition-default-time !default;

$header-user-nav_qty-counter-top-offset: -0.5rem !default;
$header-user-nav_qty-counter-right-offset-mobile: -0.5rem !default;
$header-user-nav_qty-counter-right-offset-tablet: -1rem !default;
$header-user-nav_qty-counter-right-offset-desktop: -1rem !default;

.#{$ns}header-user-nav {
    $root: &;

    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;

    @include media('>=laptop') {
        padding: 0 18px 0 0;
        min-width: 365px;
    }

    &__item {
        margin-bottom: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &--search {
            display: none;
        }
    }

    &__link {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0;

        @include media('>=laptop') {
            padding: 17px 0 20px 20px;
        }

        &:hover {
            text-decoration: none;
            #{$root}__icon {
                path {
                    fill: $header-user-nav_icon-color-hover;
                }
            }

            #{$root}__label {
                color: $header-user-nav_label-color-hover;
            }
        }

        &--search {
            &:hover {
                cursor: pointer;
            }
        }

        &.showcart {
            @include media('<laptop') {
                padding-right: 18px;
               .cs-header-user-nav__label {
                   font-size: 0;
                   > span {
                       font-size: 1.2rem;
                   }
               }
            }

            @include media('<tablet') {
                padding-right: 15px;
            }
        }
    }

    &__icon-wrapper {
        position: relative;
    }

    &__icon {
        width: $header-user-nav_icon-width;
        height: $header-user-nav_icon-height;
        display: block;
        margin: 0 auto;

        path {
            fill: $header-user-nav_icon-color;
        }
    }

    &__label {
        text-transform: $header-user-nav_label-text-transform;
        font-size: $header-user-nav_label-font-size;
        font-weight: $header-user-nav_label-font-weight;
        margin: 4px 0 0 12px;
        color: $header-user-nav_label-color;
        text-decoration: none;
        display: block;
        vertical-align: middle;

        @include media('<laptop') {
            margin: 4px 0 0 6px;
        }
    }

    &__qty-counter {

        &--wishlist {
            @include qty-counter_type('wishlist');
        }

        &--minicart {
            @include qty-counter_type('minicart');
        }

        &--have-items {
            opacity: 1;
        }

        &--empty {
            &#{$root}__qty-counter--wishlist {
                @if ($header-user-nav_qty-counter-wishlist-show-empty) {
                    opacity: 1;
                }
            }

            &#{$root}__qty-counter--minicart {
                @if ($header-user-nav_qty-counter-minicart-show-empty) {
                    opacity: 1;
                }
            }
        }
    }

    &__qty-counter-span {
        @include qty-counter_span();
    }
}

.cs-topbar__wrapper {
    .cs-header-user-nav {
        &__link {
            padding: 0;
            margin: 0;
        }
        &__icon-wrapper {
            background-color: $color_white;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            margin-top: 10px;
            padding: 0 15px;

            @include media('>=laptop') {
                margin-top: 10px;
            }
        }
        &__label {
            color: $color_black;
            font-size: 13px;
            text-transform: uppercase;
            line-height: 24px;
            font-weight: bold;
            margin: 1px 0 1px 7px;

            @include media('>=laptop') {
                margin: 3px 0 2px 10px;
            }
        }
        &__icon {
            width: 15px;
            height: 17px;
            display: block;
            margin: 0 auto;

            path {
                fill: $color_black;
            }
        }
    }
}
