@import 'config/variables';
@import 'vendors/include-media';

@import 'components/field/mixin';
@import 'components/button/mixin';

$input_padding: 0.715em 1em !default;
$input_border: 1px solid $color_alto !default;
$input_border-radius: $border-radius_base !default;

$newsletter_margin: 0 !default;
$newsletter_padding: 0 1.5rem !default;

$newsletter_form-max-width: 34rem !default;
$newsletter_form-height: 4rem !default;

$newsletter_button-type: $button_default-type !default;
$newsletter_button-icon-pos: 'left' !default;

$newsletter_button-icon-width: 0.8em !default;
$newsletter_button-icon-height: 0.8em !default;

.#{$ns}newsletter {
    margin: $newsletter_margin;
    width: 100%;

    &__form {
        position: relative;
        display: flex;
        overflow: hidden;
        padding-right: 30px !important;

        @include media('>tablet') {
            max-width: $newsletter_form-max-width;
        }

        @include media('<=tablet') {
            max-width: 86%;
        }

        @include media('>=laptop') {
            max-width: 100%;
        }

        @include media('<=phoneLg') {
            padding-right: 0 !important;
            max-width: 100%;
        }
    }

    &__field {
        flex-basis: 100%;
        border-radius: 25px;

        @include media('<=phoneLg') {
           margin-bottom: 15px;
        }
    }

    &__input {
        margin-top: 0 !important;
        border-radius: 25px;

        &.input-text {
            margin: 0;
            font-size: 1.2rem;
            line-height: 1.6rem;
            border-radius: 25px;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }

    &__actions {
        display: flex;
        align-items: stretch;
        height: $newsletter_form-height;
    }

    &__button {
        @include button(
            $type: $newsletter_button-type,
            $icon_pos: $newsletter_button-icon-pos
        );

        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 25px !important;
        border-bottom-right-radius: 25px !important;
        width: 105px;
        padding-right: 25px;
        letter-spacing: 0.6px;

        &-label {
            text-transform: uppercase;
            vertical-align: middle;
        }
    }

    &__button-icon {
        @include button-icon(
            $type: $newsletter_button-type,
            $icon_pos: $newsletter_button-icon-pos
        );

        width: $newsletter_button-icon-width;
        height: $newsletter_button-icon-height;
        margin-right: 0;
    }
}

.newsletter-lock__icon {
    font-size: 12px;
    font-weight: normal;
    background-image: url(../images/icons/lock24@1x.svg);
    background-repeat: no-repeat;
    background-size: 9px;
    background-position: 0px 7px;
    margin-top: 0;
    display: block;
    padding-left: 15px;

    @include media('<=phoneLg') {
        margin-top: 0;
        font-weight: normal;
    }
}
