@import 'config/variables';
@import 'vendors/include-media';

.#{$ns}logo {
    height: 100%;

    &__image {
        height: 100%; // Fixes height: auto bug on chrome.
    }
}
