/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.grid-container {
  box-sizing: border-box;
  display: flex;
  margin-left: -10px;
  margin-right: -10px; }
  .grid-container.layout-column {
    flex-direction: column; }
  .grid-container .col-1 {
    box-sizing: border-box;
    flex: 1 1 calc( 10% - 20px);
    width: calc( 10% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container .col-2 {
    box-sizing: border-box;
    flex: 1 1 calc( 20% - 20px);
    width: calc( 20% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container .col-3 {
    box-sizing: border-box;
    flex: 1 1 calc( 30% - 20px);
    width: calc( 30% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container .col-4 {
    box-sizing: border-box;
    flex: 1 1 calc( 40% - 20px);
    width: calc( 40% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container .col-5 {
    box-sizing: border-box;
    flex: 1 1 calc( 50% - 20px);
    width: calc( 50% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container .col-6 {
    box-sizing: border-box;
    flex: 1 1 calc( 60% - 20px);
    width: calc( 60% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container .col-7 {
    box-sizing: border-box;
    flex: 1 1 calc( 70% - 20px);
    width: calc( 70% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container .col-8 {
    box-sizing: border-box;
    flex: 1 1 calc( 80% - 20px);
    width: calc( 80% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container .col-9 {
    box-sizing: border-box;
    flex: 1 1 calc( 90% - 20px);
    width: calc( 90% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container .col-10 {
    box-sizing: border-box;
    flex: 1 1 calc( 100% - 20px);
    width: calc( 100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  @media (min-width: 48.01em) {
    .grid-container > .lg__col-1 {
      box-sizing: border-box;
      flex: 1 1 calc( 10% - 20px);
      width: calc( 10% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px; }
    .grid-container > .lg__col-2 {
      box-sizing: border-box;
      flex: 1 1 calc( 20% - 20px);
      width: calc( 20% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px; }
    .grid-container > .lg__col-3 {
      box-sizing: border-box;
      flex: 1 1 calc( 30% - 20px);
      width: calc( 30% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px; }
    .grid-container > .lg__col-4 {
      box-sizing: border-box;
      flex: 1 1 calc( 40% - 20px);
      width: calc( 40% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px; }
    .grid-container > .lg__col-5 {
      box-sizing: border-box;
      flex: 1 1 calc( 50% - 20px);
      width: calc( 50% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px; }
    .grid-container > .lg__col-6 {
      box-sizing: border-box;
      flex: 1 1 calc( 60% - 20px);
      width: calc( 60% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px; }
    .grid-container > .lg__col-7 {
      box-sizing: border-box;
      flex: 1 1 calc( 70% - 20px);
      width: calc( 70% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px; }
    .grid-container > .lg__col-8 {
      box-sizing: border-box;
      flex: 1 1 calc( 80% - 20px);
      width: calc( 80% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px; }
    .grid-container > .lg__col-9 {
      box-sizing: border-box;
      flex: 1 1 calc( 90% - 20px);
      width: calc( 90% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px; }
    .grid-container > .lg__col-10 {
      box-sizing: border-box;
      flex: 1 1 calc( 100% - 20px);
      width: calc( 100% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px; } }

@media (max-width: 48em) and (min-width: 40.01em) {
  .grid-container > .md__col-1 {
    box-sizing: border-box;
    flex: 1 1 calc( 10% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container > .md__col-2 {
    box-sizing: border-box;
    flex: 1 1 calc( 20% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container > .md__col-3 {
    box-sizing: border-box;
    flex: 1 1 calc( 30% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container > .md__col-4 {
    box-sizing: border-box;
    flex: 1 1 calc( 40% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container > .md__col-5 {
    box-sizing: border-box;
    flex: 1 1 calc( 50% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container > .md__col-6 {
    box-sizing: border-box;
    flex: 1 1 calc( 60% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container > .md__col-7 {
    box-sizing: border-box;
    flex: 1 1 calc( 70% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container > .md__col-8 {
    box-sizing: border-box;
    flex: 1 1 calc( 80% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container > .md__col-9 {
    box-sizing: border-box;
    flex: 1 1 calc( 90% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
  .grid-container > .md__col-10 {
    box-sizing: border-box;
    flex: 1 1 calc( 100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; } }
  @media (max-width: 40em) {
    .grid-container {
      flex-direction: column;
      flex-wrap: wrap; }
      .grid-container > .sm__col-1 {
        box-sizing: border-box;
        flex: 1 1 calc( 10% - 20px);
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px; }
      .grid-container > .sm__col-2 {
        box-sizing: border-box;
        flex: 1 1 calc( 20% - 20px);
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px; }
      .grid-container > .sm__col-3 {
        box-sizing: border-box;
        flex: 1 1 calc( 30% - 20px);
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px; }
      .grid-container > .sm__col-4 {
        box-sizing: border-box;
        flex: 1 1 calc( 40% - 20px);
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px; }
      .grid-container > .sm__col-5 {
        box-sizing: border-box;
        flex: 1 1 calc( 50% - 20px);
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px; }
      .grid-container > .sm__col-6 {
        box-sizing: border-box;
        flex: 1 1 calc( 60% - 20px);
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px; }
      .grid-container > .sm__col-7 {
        box-sizing: border-box;
        flex: 1 1 calc( 70% - 20px);
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px; }
      .grid-container > .sm__col-8 {
        box-sizing: border-box;
        flex: 1 1 calc( 80% - 20px);
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px; }
      .grid-container > .sm__col-9 {
        box-sizing: border-box;
        flex: 1 1 calc( 90% - 20px);
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px; }
      .grid-container > .sm__col-10 {
        box-sizing: border-box;
        flex: 1 1 calc( 100% - 20px);
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px; } }

.content-short {
  max-width: 860px;
  margin: 0 auto;
  padding: 0 10px; }

.v-align {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.narrow-content {
  max-width: 860px !important;
  margin: 20px auto; }

.columns .column.main {
  width: auto;
  padding: 0 !important;
  max-width: 100%;
  min-height: 10rem; }
  @media (max-width: 64em) {
    .columns .column.main {
      padding: 0; } }

.cs-container--page-bottom {
  background: #000; }

.cs-page-bottom {
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 48em) {
    .cs-page-bottom {
      flex-wrap: wrap;
      justify-content: flex-start; } }
  @media (min-width: 64em) {
    .cs-page-bottom {
      flex-wrap: nowrap;
      justify-content: center; } }
  .page-print .cs-page-bottom {
    display: none; }
  .cs-page-bottom__image {
    height: 3rem;
    width: auto; }
    .cs-page-bottom__image--circle {
      width: 4.7rem;
      height: 4.7rem;
      min-width: 4.7rem;
      min-height: 4.7rem;
      margin-right: 1rem; }
  .cs-page-bottom__text,
  .cs-page-bottom .copyright {
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 0;
    margin-right: 0.6rem; }
    .cs-page-bottom__text--small,
    .cs-page-bottom .copyright--small {
      font-size: 1rem; }
  .cs-page-bottom__group {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: space-between; }
    @media (min-width: 48em) {
      .cs-page-bottom__group {
        width: 100%;
        padding: 1rem 0; } }
    @media (min-width: 64em) {
      .cs-page-bottom__group {
        width: auto;
        padding: 0; } }
    .cs-page-bottom__group--info {
      display: none;
      flex-direction: row-reverse; }
      @media (min-width: 48em) {
        .cs-page-bottom__group--info {
          display: flex;
          flex-basis: 40%;
          justify-content: flex-end;
          padding-right: 1.5rem; }
          .cs-page-bottom__group--info .cs-page-bottom__text {
            max-width: 20rem; } }
      @media (min-width: 64em) {
        .cs-page-bottom__group--info {
          flex-basis: 17%;
          padding-right: 0; }
          .cs-page-bottom__group--info .cs-page-bottom__text {
            max-width: 100%; } }
    .cs-page-bottom__group--shipping {
      display: none; }
      @media (min-width: 48em) {
        .cs-page-bottom__group--shipping {
          display: flex;
          flex-basis: 55%;
          padding-left: 1.5rem; } }
      @media (min-width: 64em) {
        .cs-page-bottom__group--shipping {
          flex-basis: 33%;
          padding-left: 4rem; } }
    .cs-page-bottom__group--payment {
      flex-basis: 50%; }
      @media (max-width: 47.99em) {
        .cs-page-bottom__group--payment .cs-page-bottom__text {
          display: none; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-page-bottom__group--payment {
    flex-basis: 100%; } }
      @media (min-width: 64em) {
        .cs-page-bottom__group--payment {
          padding-left: 4rem; } }
    @media (min-width: 64em) {
      .cs-page-bottom__group--shipping, .cs-page-bottom__group--payment {
        position: relative; }
        .cs-page-bottom__group--shipping:before, .cs-page-bottom__group--payment:before {
          margin: 0 1rem;
          content: '';
          position: absolute;
          left: 1rem;
          top: 50%;
          width: 0.2rem;
          height: 2rem;
          background: #ffffff;
          transform: translateY(-50%); } }
  .cs-page-bottom__logo {
    display: flex;
    flex-shrink: 1;
    height: 2rem; }
    @media (max-width: 63.99em) {
      .cs-page-bottom__logo {
        width: auto; } }
    .cs-page-bottom__logo--hermes {
      width: 12.2rem; }
    .cs-page-bottom__logo--dhl {
      height: auto;
      max-height: 2rem;
      min-width: 8.6rem;
      padding-left: 10px; }
    .cs-page-bottom__logo--paypal {
      width: 6.2rem; }
    .cs-page-bottom__logo--visa {
      height: auto;
      max-height: 2rem;
      min-width: 5.5rem; }
    .cs-page-bottom__logo--mastercard {
      width: 3.3rem; }
    .cs-page-bottom__logo--klarna-sofort {
      height: auto;
      max-height: 2.5rem;
      min-height: 2.5rem; }
    .cs-page-bottom__logo--klarna {
      width: 7rem; }
    .cs-page-bottom__logo--gls {
      width: 5rem; }
    .cs-page-bottom__logo--giropay {
      width: 4.8rem; }
    .cs-page-bottom__logo--ec {
      width: 1.8rem; }
    .cs-page-bottom__logo--paydirekt {
      width: 6rem; }
    .cs-page-bottom__logo--lastschrift {
      width: 6.15rem; }
    .cs-page-bottom__logo--vorkasse {
      width: 8.3rem; }
    .cs-page-bottom__logo--rechnung {
      width: 6.15rem; }
    .cs-page-bottom__logo + .cs-page-bottom__logo {
      margin-left: 0.5rem; }

@media (max-width: 40em) {
  .cs-container--page-pdp-bottom,
  .cs-container--products-carousel {
    padding: 0; } }

.cs-container--page-bottom {
  max-width: none; }

.content-row .container {
  display: flex; }
  .content-row .container.row {
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: -10px;
    margin-right: -10px; }
  .content-row .container.column {
    flex-direction: column; }
