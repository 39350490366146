/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-topbar {
  background: transparent;
  display: flex;
  width: 100%;
  font-size: 1.4rem;
  transform: translateY(0);
  transition: transform 0.3s;
  z-index: 302; }
  .cs-topbar--hidden {
    transform: translateY(-105%); }
  .page-print .cs-topbar {
    display: none; }
  .cs-topbar__wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between; }
    @media (min-width: 64em) {
      .cs-topbar__wrapper {
        justify-content: flex-end; } }
    @media (min-width: 64em) {
      .cs-topbar__wrapper {
        padding-right: 18px;
        padding-top: 5px; } }
    @media (max-width: 63.99em) {
      .cs-topbar__wrapper {
        padding: 0 25px; } }
    @media (max-width: 47.99em) {
      .cs-topbar__wrapper {
        padding: 0 15px; } }
  .cs-topbar__item {
    color: #ffffff;
    display: flex;
    flex: 0 0 auto;
    white-space: nowrap;
    height: 100%; }
    .cs-topbar__item--hotline {
      align-items: center; }
      .cs-topbar__item--hotline ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0; }
        .cs-topbar__item--hotline ul li {
          display: inline-block;
          margin: 0; }
          @media (max-width: 48em) {
            .cs-topbar__item--hotline ul li:not(:last-child) {
              display: none; } }
      .cs-topbar__item--hotline a {
        padding: 8px 0 0;
        display: block;
        letter-spacing: 0.5px; }
        .cs-topbar__item--hotline a {
          color: #ffffff; }
          .cs-topbar__item--hotline a:visited {
            color: #ffffff; }
          .cs-topbar__item--hotline a:hover {
            color: #ffffff; }
          .cs-topbar__item--hotline a:active, .cs-topbar__item--hotline a:focus {
            color: #ffffff; }
        .cs-topbar__item--hotline a:hover {
          color: #FF4604; }
        @media (min-width: 48em) {
          .cs-topbar__item--hotline a {
            padding: 8px 15px 0 0; } }
        @media (min-width: 48em) {
          .cs-topbar__item--hotline a {
            padding: 0 15px 0 0; } }
        @media (min-width: 64em) {
          .cs-topbar__item--hotline a {
            padding: 10px 28px 0; } }
      @media (max-width: 64em) {
        .cs-topbar__item--hotline {
          width: initial; } }
      @media (min-width: 64em) {
        .cs-topbar__item--hotline {
          flex: 1;
          justify-content: flex-end; } }
    .cs-topbar__item--authorization {
      margin-left: 30px; }
  .cs-topbar__phone-number {
    color: #000;
    font-weight: 700;
    margin-right: 0.9rem; }
    .cs-topbar__phone-number:hover {
      color: #FF4604; }
