@import 'config/variables';
@import 'config/breakpoints';
@import 'vendors/include-media';
@import 'components/typography/mixin';
@import 'components/pseudoicon/mixin';
@import 'utils/component-spacing';
@import 'utils/reset-list-style';

$accordion_group-margin: 0 0 3rem !default;

$accordion_group-headline-margin: 0 0 0.5rem !default;
$accordion_group-headline-font-size: $font-size_headline-3 !default;

$accordion_item-separator: none;
$accordion_item-font-weight: inherit !default;
$accordion_item-padding: 1.2rem 0 !default;

$accordion_item-headline-icon-use-pseudoicon-instead-svg: true !default;
$accordion_item-headline-icon-position: left !default;
$accordion_item-headline-icon-spacing-from-text: 1rem !default;
$accordion_item-headline-icon-width: 1rem !default;
$accordion_item-headline-icon-height: 0.2rem !default;
$accordion_item-headline-icon-fill: $color_primary-500 !default;
$accordion_item-headline-icon-fill--hover: $accordion_item-headline-icon-fill !default;
$accordion_item--active-headline-icon-fill: $accordion_item-headline-icon-fill !default;
$accordion_item--active-headline-icon-rotate: true !default;
$accordion_item--active-content-display: block !default;
// Below navigation_icon settings are usable only if you use pseudoicon
$accordion_item-headline-icon-type: 'sign' !default;
$accordion_item-headline-icon-default--opened: 'up' !default;
$accordion_item-headline-icon-default--closed: 'down' !default;

$accordion_item-headline-icon-position-side-offset: 1.8rem !default;
$accordion_item-headline-icon-arrow-left-offset: $accordion_item-headline-icon-position-side-offset +
    0.6rem !default;

.#{$ns}accordion {
    $root: &;

    @include component-spacing();

    &__groups,
    &__items {
        @include reset-list-style();
    }

    &__group {
        margin: $accordion_group-margin;
    }

    &__group-headline {
        @include headline($level: $accordion_group-headline-font-size);
        margin: $accordion_group-headline-margin;
    }

    &__item {
        margin: 0 0 15px;
        padding: 0;

        &--active {
            #{$root}__item-headline {
                background: $color_dark-gray;

                &:before {
                    background-image: url('../images/icons/ac_minus.svg');
                }

                span {
                    color: $color_white;
                }
            }

            #{$root}__item-content {
                display: $accordion_item--active-content-display;
            }
        }
    }

    &__item-headline {
        position: relative;
        padding: 1.1rem 0;
        cursor: pointer;
        background: $color_light-gray;

        &:before {
            content: '';
            background-image: url('../images/icons/ac_plus.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            display: block;
            width: 30px;
            height: 30px;
            position: absolute;
            right: 2.2rem;
            top: 10px;
        }
    }

    &__item-headline-span {
        position: relative;
        display: inline-flex;
        align-items: center;
        font-weight: $accordion_item-font-weight;
        padding: 0 4.5rem 0 2rem;
        font-weight: bold;
        font-size: 1.8rem;

        @include media('<tablet') {
            width: 100%;

            @if ($accordion_item-headline-icon-position == right) {
                justify-content: space-between;
            }
        }
    }

    &__item-content {
        display: none;
        margin: 1.2rem 0 2rem;
        padding: 0 5px;
        font-size: 1.6rem;

        p:last-child {
            margin-bottom: 0;
        }
    }
}
