/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-offcanvas-toggle {
  position: relative;
  display: flex;
  border: 0;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  align-self: stretch;
  height: 100%;
  width: 100%; }
  .cs-offcanvas-toggle, .cs-offcanvas-toggle:active, .cs-offcanvas-toggle:focus, .cs-offcanvas-toggle:hover {
    border: 0;
    background: transparent;
    box-shadow: none;
    border-radius: 0; }
  .cs-offcanvas-toggle:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    transform: scale(0);
    background: #FF4604;
    transition: transform 0.3s;
    bottom: 0;
    left: 0;
    transform-origin: left; }
  .cs-offcanvas-toggle--active, .cs-offcanvas-toggle--active:active, .cs-offcanvas-toggle--active:focus, .cs-offcanvas-toggle--active:hover {
    background: #FF4604; }
  .cs-offcanvas-toggle--active:after {
    transform: scale(1); }
  .cs-offcanvas-toggle--active .cs-offcanvas-toggle__icon {
    transform: rotate(-45deg); }
    .cs-offcanvas-toggle--active .cs-offcanvas-toggle__icon, .cs-offcanvas-toggle--active .cs-offcanvas-toggle__icon:before, .cs-offcanvas-toggle--active .cs-offcanvas-toggle__icon:after {
      background: #ffffff; }
    .cs-offcanvas-toggle--active .cs-offcanvas-toggle__icon:before, .cs-offcanvas-toggle--active .cs-offcanvas-toggle__icon:after {
      transform: rotate(90deg); }
  .cs-offcanvas-toggle__icon {
    padding: 0;
    margin: 0;
    position: relative;
    display: block;
    width: 22px;
    height: 22px; }
    .cs-offcanvas-toggle__icon:before {
      content: '';
      display: block;
      background-image: url("../images/icons/menu.svg");
      background-repeat: no-repeat;
      background-size: 21px;
      background-position: center;
      width: 100%;
      height: 100%; }
  .cs-offcanvas-toggle__text {
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    color: #212121;
    text-transform: uppercase;
    transition: color 0.3s; }
    .cs-offcanvas-toggle--active .cs-offcanvas-toggle__text {
      color: #ffffff; }
    @media (min-width: 48em) {
      .cs-offcanvas-toggle__text {
        bottom: 2rem; } }
