@import 'config/variables';
@import 'vendors/include-media';

$offcanvas-toggle_icon-background: transparent !default;
$offcanvas-toggle_icon-color: $color_primary-500 !default;
$offcanvas-toggle_icon-background-active: $color_primary-500 !default;
$offcanvas-toggle_icon-color-active: $color_background-200 !default;
$offcanvas-toggle_icon-transition-time: $transition-default-time !default;
$offcanvas-toggle_animation-direction: 'left' !default; // 'left' or 'right'
$offcanvas-toggle_text-color: $color_text-800 !default;
$offcanvas-toggle_text-color-active: $color_text-200 !default;
$offcanvas-toggle_text-transform: uppercase !default;

.cs-offcanvas-toggle {
    $root: &;

    position: relative;
    display: flex;
    border: 0;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    align-self: stretch;
    height: 100%;
    width: 100%;

    &,
    &:active,
    &:focus,
    &:hover {
        border: 0;
        background: $offcanvas-toggle_icon-background;
        box-shadow: none;
        border-radius: 0;
    }

    &:after {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        transform: scale(0);
        background: $offcanvas-toggle_icon-background-active;
        transition: transform $offcanvas-toggle_icon-transition-time;
        bottom: 0;

        @if ($offcanvas-toggle_animation-direction == 'right') {
            right: 0;
            transform-origin: right;
        } @else {
            left: 0;
            transform-origin: left;
        }
    }

    &--active {
        &,
        &:active,
        &:focus,
        &:hover {
            background: $offcanvas-toggle_icon-background-active;
        }

        &:after {
            transform: scale(1);
        }

        #{$root}__icon {
            transform: rotate(-45deg);

            &,
            &:before,
            &:after {
                background: $offcanvas-toggle_icon-color-active;
            }

            &:before,
            &:after {
                transform: rotate(90deg);
            }
        }
    }

    &__icon {
        padding: 0;
        margin: 0;
        position: relative;
        display: block;
        width: 22px;
        height: 22px;

        &:before {
            content: '';
            display: block;
            background-image: url("../images/icons/menu.svg");
            background-repeat: no-repeat;
            background-size: 21px;
            background-position: center;
            width: 100%;
            height: 100%;
        }
    }

    &__text {
        position: absolute;
        bottom: 0.5rem;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 0.8rem;
        color: $offcanvas-toggle_text-color;
        text-transform: $offcanvas-toggle_text-transform;
        transition: color 0.3s;

        #{$root}--active & {
            color: $offcanvas-toggle_text-color-active;
        }

        @include media('>=tablet') {
            bottom: 2rem;
        }
    }
}
