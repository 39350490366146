/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-video-player {
  max-height: calc(100vh - 12rem); }
  .cs-video-player__wrapper {
    display: none; }
    .cs-video-player__modal .cs-video-player__wrapper {
      display: block; }
  .cs-video-player__modal {
    display: block;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0; }
    .cs-video-player__modal._show .modal-inner-wrap {
      transform: translateY(-50%); }
    @media (max-width: 47.99em) {
      .cs-video-player__modal._show {
        left: 0; }
        .cs-video-player__modal._show .action-close:before {
          font-size: 5.5rem;
          line-height: 5.5rem; } }
    .cs-video-player__modal .modal-inner-wrap {
      overflow: hidden;
      top: 50%; }
      @media (max-width: 47.99em) {
        .cs-video-player__modal .modal-inner-wrap {
          width: 100% !important;
          background: #ffffff;
          padding: 1rem;
          box-sizing: border-box; } }
      @media (min-width: 48em) {
        .cs-video-player__modal .modal-inner-wrap {
          width: 98vw;
          max-height: 94vh !important;
          margin: auto !important;
          padding: 3rem; } }
      @media (min-width: 64em) {
        .cs-video-player__modal .modal-inner-wrap {
          width: 86vw; } }
    .cs-video-player__modal .cs-modal__header {
      width: 100%;
      margin: 0;
      text-align: right;
      padding: 0 !important;
      box-sizing: border-box; }
      @media (max-width: 47.99em) {
        .cs-video-player__modal .cs-modal__header {
          min-height: 4.5rem; } }
    .cs-video-player__modal .cs-modal__close {
      margin: 0 !important; }
    .cs-video-player__modal .cs-modal__content {
      width: 100%;
      box-sizing: border-box;
      padding: 0 !important; }
