/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
.cs-accordion {
  margin: 0 0 3rem; }
  @media (min-width: 48em) {
    .cs-accordion {
      margin: 0 0 4rem; } }
  .cs-accordion--skip-spacing {
    margin: 0; }
  .cs-accordion__groups, .cs-accordion__items {
    margin: 0;
    padding: 0;
    list-style: none; }
  .cs-accordion__group {
    margin: 0 0 3rem; }
  .cs-accordion__group-headline {
    font-family: "Red Hat Display", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.8rem;
    color: #212121;
    font-weight: 700;
    text-transform: none;
    margin: 1rem 0 2rem;
    margin: 0 0 0.5rem; }
  .cs-accordion__item {
    margin: 0 0 15px;
    padding: 0; }
    .cs-accordion__item--active .cs-accordion__item-headline {
      background: #575757; }
      .cs-accordion__item--active .cs-accordion__item-headline:before {
        background-image: url("../images/icons/ac_minus.svg"); }
      .cs-accordion__item--active .cs-accordion__item-headline span {
        color: #ffffff; }
    .cs-accordion__item--active .cs-accordion__item-content {
      display: block; }
  .cs-accordion__item-headline {
    position: relative;
    padding: 1.1rem 0;
    cursor: pointer;
    background: #F4F4F4; }
    .cs-accordion__item-headline:before {
      content: '';
      background-image: url("../images/icons/ac_plus.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 2.2rem;
      top: 10px; }
  .cs-accordion__item-headline-span {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-weight: inherit;
    padding: 0 4.5rem 0 2rem;
    font-weight: bold;
    font-size: 1.8rem; }
    @media (max-width: 47.99em) {
      .cs-accordion__item-headline-span {
        width: 100%; } }
  .cs-accordion__item-content {
    display: none;
    margin: 1.2rem 0 2rem;
    padding: 0 5px;
    font-size: 1.6rem; }
    .cs-accordion__item-content p:last-child {
      margin-bottom: 0; }
