@import 'config/variables';
@import 'utils/component-spacing';

.#{$ns}products-grid {
    @include component-spacing();

    .cs-addtocart {
        .cs-qty-increment {
            display: none;
        }
    }
}
