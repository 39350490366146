@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/get-value-from-list';
@import 'components/pseudoicon/mixin';

$offcanvas-navigation_background: $color_light-gray !default;

$offcanvas-navigation_item-background: $offcanvas-navigation_background !default;
$offcanvas-navigation_item-border: 1px $color_alto solid !default;

$offcanvas-navigation_link-color: $color_text-800 !default;
$offcanvas-navigation_link-color--hover: $offcanvas-navigation_link-color !default;
$offcanvas-navigation_link-font-size: 1.4rem !default;
$offcanvas-navigation_link-font-weight: 700 !default;
$offcanvas-navigation_link-sign-color: $color_primary-500 !default;
$offcanvas-navigation_link-sign-color--hover: $offcanvas-navigation_link-sign-color !default;
$offcanvas-navigation_link-active-color: $color_primary-500 !default;
$offcanvas-navigation_link-padding: 15px !default;

$offcanvas-navigation_icon-use-pseudoicon-instead-svg: true !default;
$offcanvas-navigation_icon-width: 0.8rem !default;
$offcanvas-navigation_icon-height: 0.2rem !default;
$offcanvas-navigation_icon-color: $color_primary-500 !default;
$offcanvas-navigation_icon-color--hover: $offcanvas-navigation_icon-color !default;
// Below navigation_icon settings are usable only if you use pseudoicon
$offcanvas-navigation_icon-type: 'arrow' !default;
$offcanvas-navigation_icon-default--next: 'right' !default;
$offcanvas-navigation_icon-default--prev: 'left' !default;
$offcanvas-navigation_icon-animation: false !default;
$offcanvas-navigation_icon-arrow-left-offset: 0 !default;

$offcanvas-navigation_icon-sign-color: $color_primary-500;
$offcanvas-navigation_icon-sign-color--hover: $offcanvas-navigation_icon-sign-color;

$offcanvas-navigation_product-qty-color: $color_text-500 !default;

.#{$ns}offcanvas-navigation {
    $root: &;

    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: $offcanvas-navigation_background;

    .nav-logo {
        background: $color_body-text;
        padding: 17px;
        img {
            max-width: 160px;
            display: block;
            margin: 0 auto;
        }
    }

    &__list {
        position: relative;
        z-index: 1;
        width: 100%;
        min-width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        background-color: $offcanvas-navigation_item-background;
        overflow-y: hidden;
        overflow-x: hidden;
        height: 100%;
        -webkit-overflow-scrolling: touch;

        &--current {
            overflow-y: auto;
        }

        & & {
            position: absolute;
            left: 105%;
            height: 100%;
            top: 0;
            transition: transform 0.1s;

            &--active {
                transform: translateX(-105%);
                transform: translate3d(-105%, 0, 0);
            }
        }

        > li:not(.cs-offcanvas-navigation__item){
            @extend .cs-offcanvas-navigation__item;
            border-top: $offcanvas-navigation_item-border;

            &:last-of-type {
                border-bottom: $offcanvas-navigation_item-border;
            }

            a {
                @extend .cs-offcanvas-navigation__link;
            }
        }
        &--level_1,
        &--level_2 {
            .cs-offcanvas-navigation__link:not(.cs-offcanvas-navigation__link--return) {
                font-weight: 400;
            }
        }
    }

    &__item {
        width: 100%;
        display: flex;
        overflow: hidden;
        margin: 0;

        & + & {
            border-top: $offcanvas-navigation_item-border;
        }

        &:last-of-type {
            border-bottom: $offcanvas-navigation_item-border;
        }

        &--with-icon {
            #{$root}__text {
                position: relative;
                padding-left: get-value-from-list(
                    $offcanvas-navigation_link-padding,
                    'left'
                );
            }
        }
    }

    &__link {
        min-width: 100%;
        width: 100%;
        display: flex;
        padding: $offcanvas-navigation_link-padding;
        font-size: $offcanvas-navigation_link-font-size;
        align-items: center;
        text-transform: none;
        font-weight: $offcanvas-navigation_link-font-weight;
        position: relative;

        &,
        &:visited {
            color: $offcanvas-navigation_link-color;
        }

        &:hover {
            color: $offcanvas-navigation_link-color--hover;

            @if not($offcanvas-navigation_icon-use-pseudoicon-instead-svg) {
                #{$root}__icon {
                    path {
                        fill: $offcanvas-navigation_icon-color--hover;
                    }
                }
            }
        }

        &--sign-in,
        &--sign-out {
            padding-right: 5rem;

            &,
            &:visited {
                color: $offcanvas-navigation_link-sign-color;
            }

            &:hover {
                color: $offcanvas-navigation_link-sign-color--hover;

                #{$root}__icon {
                    path {
                        fill: $offcanvas-navigation_icon-sign-color--hover;
                    }
                }
            }

            #{$root}__icon {
                width: 2.3rem;
                height: 2.7rem;
                right: get-value-from-list(
                    $offcanvas-navigation_link-padding,
                    'right'
                );

                path {
                    fill: $offcanvas-navigation_icon-sign-color;
                }
            }
        }

        &--parent,
        &--return {
            #{$root}__icon {
                width: $offcanvas-navigation_icon-width;
                height: $offcanvas-navigation_icon-width;
            }
        }

        &--parent {
            padding-right: 5rem;

            #{$root}__icon {
                right: get-value-from-list(
                    $offcanvas-navigation_link-padding,
                    'right'
                );
            }
        }

        &--return {
            padding-left: 5rem;

            #{$root}__icon {
                left: get-value-from-list(
                    $offcanvas-navigation_link-padding,
                    'left'
                );
            }
        }

        @if ($offcanvas-navigation_icon-use-pseudoicon-instead-svg) {
            &--next {
                @include pseudoicon(
                    $type: $offcanvas-navigation_icon-type,
                    $animated: $offcanvas-navigation_icon-animation,
                    $arrow-default: $offcanvas-navigation_icon-default--next,
                    $arrow-offset: $offcanvas-navigation_icon-arrow-left-offset,
                    $width: $offcanvas-navigation_icon-width,
                    $height: $offcanvas-navigation_icon-height,
                    $color: $offcanvas-navigation_icon-color,
                    $color-hover: $offcanvas-navigation_icon-color--hover,
                    $position-side-offset:
                        get-value-from-list(
                            $offcanvas-navigation_link-padding,
                            'right'
                        )
                );
            }

            &--prev {
                @include pseudoicon(
                    $type: $offcanvas-navigation_icon-type,
                    $animated: $offcanvas-navigation_icon-animation,
                    $arrow-default: $offcanvas-navigation_icon-default--prev,
                    $arrow-offset: $offcanvas-navigation_icon-arrow-left-offset,
                    $width: $offcanvas-navigation_icon-width,
                    $height: $offcanvas-navigation_icon-height,
                    $color: $offcanvas-navigation_icon-color,
                    $color-hover: $offcanvas-navigation_icon-color--hover,
                    $position-side-offset: auto
                );

                &:before,
                &:after {
                    left: get-value-from-list(
                        $offcanvas-navigation_link-padding,
                        'left'
                    );
                }
            }
        }
    }

    .active {
        > #{$root}__link {
            color: $offcanvas-navigation_link-active-color;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        path {
            fill: $offcanvas-navigation_icon-color;
        }
    }

    &__category-icon-wrapper {
        position: absolute;
        width: 2rem;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    &__category-icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);
        max-height: 2rem;
    }

    &__product-qty {
        font-weight: 400;
        color: $offcanvas-navigation_product-qty-color;

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
}
