/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.messages {
  max-width: 124rem;
  width: 100%;
  margin: 0 auto; }

.cs-messages__message,
.message {
  margin: 1rem 0;
  padding: 1rem 1rem 1.2rem;
  border-radius: 0;
  background-repeat: no-repeat;
  background-position: 1.5rem center;
  background-size: 1.5rem 1.5rem;
  padding-left: 4.3rem; }

.cs-messages__message--success,
.message.success {
  background-color: transparent;
  background-image: url("../images/messages/success.svg");
  border: 1.5px solid #A9C546;
  background-size: 1.9rem 1.9rem; }
  .cs-messages__message--success .cs-messages__text,
  .cs-messages__message--success span,
  .message.success .cs-messages__text,
  .message.success span {
    color: #A9C546; }

.cs-messages__message--notice,
.message.warning {
  background-color: transparent;
  background-image: url("../images/messages/warning.svg");
  border: 1.5px solid #FF7442;
  background-position: 1.6rem center;
  background-size: 1.6rem 1.6rem; }
  .cs-messages__message--notice .cs-messages__text,
  .cs-messages__message--notice span,
  .message.warning .cs-messages__text,
  .message.warning span {
    color: #FF7442; }

.cs-messages__message--info,
.message.info,
.message.notice {
  background-color: transparent;
  background-image: url("../images/messages/info.svg");
  border: 1.5px solid #4A90E2;
  background-position: 1.6rem center;
  background-size: 1.5rem 1.5rem; }
  .cs-messages__message--info .cs-messages__text,
  .cs-messages__message--info span,
  .message.info .cs-messages__text,
  .message.info span,
  .message.notice .cs-messages__text,
  .message.notice span {
    color: #4A90E2; }

.cs-messages__message--error,
.message.error {
  background-color: transparent;
  background-image: url("../images/messages/remove.svg");
  border: 1.5px solid #CC1C3A; }
  .cs-messages__message--error .cs-messages__text,
  .cs-messages__message--error span,
  .message.error .cs-messages__text,
  .message.error span {
    color: #CC1C3A; }

.cs-messages__text,
.message span {
  font-size: 1.6rem; }
  @media (max-width: 48em) {
    .cs-messages__text,
    .message span {
      line-height: 20px; } }

.cs-messages--space-top {
  margin-top: 1.5rem; }

.increment-notification {
  margin-top: 15px;
  display: inline-block;
  line-height: 20px;
  max-width: 100%;
  width: 100%;
  background-position: 1.6rem 1.2rem !important; }
